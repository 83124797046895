import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Start from '../views/Start'
import Tierone from '../views/Tierone'
import Tiertwo from '../views/Tiertwo'
import Tierthree from '../views/Tierthree'
import Title from '../views/TitleDetails'
import Login from '../views/Login'
import Register from '../views/Register'
import PasswordReset from '../views/PasswordReset'
import ChangeEmail from '../views/ChangeEmail'
import Account from '../views/Account'
// import Streamers from '../views/Streamers'
import Services from '../views/Services'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { transition: 'none' },
  },
  {
    path: '/start',
    name: 'Start',
    component: Start,
    meta: { transition: 'zoom' },
  },
  {
    path: '/tierone/:tierKey',
    name: 'Tierone',
    component: Tierone,
    props: true,
    meta: { transition: 'zoom' },
  },
  {
    path: '/tiertwo/:tierKey',
    name: 'Tiertwo',
    component: Tiertwo,
    props: true,
    meta: { transition: 'zoom' },
  },
  {
    path: '/tierthree/:tierKey',
    name: 'Tierthree',
    component: Tierthree,
    props: true,
    meta: { transition: 'zoom' },
  },
  {
    path: '/title/:id',
    name: 'Title',
    component: Title,
    props: true,
    meta: { transition: 'zoom' },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { transition: 'zoom' },
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: { transition: 'zoom', requiresAuth: true },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { transition: 'zoom' },
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: { transition: 'zoom' },
  },
  {
    path: '/change-email',
    name: 'ChangeEmail',
    component: ChangeEmail,
    meta: { transition: 'zoom' },
  },
  // {
  //   path: '/streamers',
  //   name: 'Streamers',
  //   component: Streamers,
  //   meta: { transition: 'zoom' },
  // },
  {
    path: '/services',
    name: 'Services',
    component: Services,
    meta: { transition: 'zoom' },
  },
  // {
  //   path: 'streamer/new',
  //   name: 'Streamer',
  //   component: '',
  //   props: true,
  //   meta: { transition: 'zoom', requiresAuth: true },
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  await store.dispatch('auth/setAuth')
  const auth = store.getters['auth/getAuth']()
  const currentUser = auth?.currentUser

  if (requiresAuth && !currentUser) {
    next({ path: '/login', query: { redirect: to.fullPath } })
  }
  else { 
    next()
  }
})

export default router
