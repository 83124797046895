<template>
  <v-card>
    <progress-indicator></progress-indicator>
    <v-app-bar
      app
      color="gray"
      dark
      class="mb-6 justify-start"
      extension-height="35"
    >
      <v-btn
        x-small
        elevation="0"
        @click="backbuttonClicked()"
        class="no-background-hover"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        x-small
        elevation="0"
        @click="goHome()"
        class="no-background-hover"
      >
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        x-small
        elevation="0"
        @click="drawer = !drawer"
        class="no-background-hover"
      >
        <v-icon v-if="!drawer">mdi-magnify</v-icon>
        <v-icon v-else>mdi-magnify-close</v-icon>
      </v-btn>
      <title-search v-show="drawer"></title-search>
      <social-sharing v-show="!drawer"></social-sharing>
      <v-btn
        x-small
        elevation="0"
        v-show="!drawer"
        class="no-background-hover"
        @click="onFilterSlideIn()"
      >
        <v-icon size="25">mdi-tune-variant</v-icon>
      </v-btn>
      <v-btn
        x-small
        elevation="0"
        v-show="!accountDrawer && !drawer"
        @click="onAccountSlideIn()"
        class="no-background-hover"
      >
        <v-icon size="25">mdi-account</v-icon>
      </v-btn>
      <more-menu v-show="!drawer"></more-menu>
    </v-app-bar>
    <filter-slide-in></filter-slide-in>
    <account-slide-in></account-slide-in>
  </v-card>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import SocialSharing from './SocialSharing'
import MoreMenu from './MoreMenu'
import TitleSearch from './TitleSearch'
import ProgressIndicator from '../components/ProgressIndicator'
import FilterSlideIn from '../components/FilterSlideIn'
import AccountSlideIn from '../components/AccountSlideIn'

export default {
  name: 'AppToolbar',
  mixins: [clickaway],
  props: ['id', 'title'],
  components: {
    SocialSharing,
    MoreMenu,
    TitleSearch,
    ProgressIndicator,
    FilterSlideIn,
    AccountSlideIn,
  },
  data: () => ({
    drawer: null,
    accountDrawer: null,
  }),
  methods: {
    backbuttonClicked() {
      setTimeout(() => {
        this.$router.back()
      }, 100)
    },
    goHome() {
      setTimeout(() => {
        this.$router.push(`/start`)
      }, 100)
    },
    onFilterSlideIn() {
      setTimeout(() => {
        this.$store.dispatch('toggleFilterSlideIn')
      }, 100)
    },
    onAccountSlideIn() {
      setTimeout(() => {
        this.$store.dispatch('toggleAccountSlideIn')
      }, 100)
    },
    away() {
      this.drawer = false
    },
  },
  mounted() {},
}
</script>
<style scoped>
.no-background-hover::before {
  background-color: transparent !important;
}
</style>
