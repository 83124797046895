<template>
  <services-horizontal-list :items="items"></services-horizontal-list>
</template>

<script>
import ServicesHorizontalList from './ServicesHorizontalList'
export default {
  components: {
    ServicesHorizontalList,
  },

  name: 'ServicesCollection',
  props: ['items'],
  data: () => ({
    showDialog: true,
  }),
  methods: {},
  computed: {},
  watch: {
    // items: function (newVal) {
    //   console.log('prop changed', newVal, oldVal)
    //   if (newVal) {
    //     let result = !!newVal.length
    //     if (result) {
    //       // get the localstorage subscriptions
    //       const subscriptions = JSON.parse(
    //         localStorage.getItem('subscriptions')
    //       )
    //       if (subscriptions) {
    //         for (let x = 0; x < subscriptions.length; x++) {
    //           newVal.find((item) => {
    //             if (item.jwProviderId === subscriptions[x]) {
    //               item.subscribed = true
    //             }
    //           })
    //         }
    //       }
    //     }
    //   }
    // },
  },
  mounted() {},
}
</script>

<style scoped></style>
