import dataApi from '../../services/dataApi'

export default {
  namespaced: true,
  state: {
    titles: [],
  },
  getters: {
    getTitles: (state) => () => {
      return state.titles
    },
    getNcTitleId: (state) => (id) => {
      return state.titles.find((item) => item.id === id)
    },
  },
  mutations: {
    SET_TITLES(state, data) {
      // go through the list and set up a transposition id
      for (let x = 0; x < data.length; x++) {
        data[x].id = x + 1
      }
      state.titles = data
    },
  },
  actions: {
    async fetchTitles({ commit }) {
      let response = await dataApi.getTitles()
      if (response.data) {
        commit('SET_TITLES', response.data)
      }
    },
  },
}
