<template>
  <v-card class="pl-0 pr-0 pb-0">
    <v-toolbar dense elevation="0" class="ml-0 pl-0" height="60">
      <span class="d-flex align-content-start flex-wrap">
        <div class="dotted-left-border red-border ml-0 mb-0">
          <div class="d-inline-block text-body-2 mt-1 ml-4 text-truncate">
            <span v-if="tier === 'tierone'" class="text-caption text-lg-h6">
              Pick a title or choose a second word
            </span>
            <span v-else class="text-caption text-lg-h6"
              >Pick a title or choose a third word
            </span>
            <v-icon small color="orange darken-2">mdi-arrow-down-right</v-icon>
          </div>
        </div>
      </span>
      <v-spacer></v-spacer>
      <span>
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-arrow-expand-all</v-icon>
            </v-btn>
          </template>

          <v-card class="d-flex flex-wrap" flat>
            <v-app-bar app dark dense elevation="0">
              <v-toolbar-title></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-app-bar>
            <div>
              <v-card class="d-flex justify-space-between flex-wrap mt-12" flat>
                <nc-keyword-card
                  v-for="(item, index) in items"
                  :key="index"
                  :item="item"
                  class="ma-2"
                ></nc-keyword-card>
              </v-card>
            </div>
          </v-card>
        </v-dialog>
      </span>
    </v-toolbar>
  </v-card>
</template>

<script>
import NcKeywordCard from './NcKeywordCard'
export default {
  components: { NcKeywordCard },
  name: 'NcKeywordHorizontalList',
  props: ['items', 'tier'],
  data: () => ({
    dialog: false,
  }),
  methods: {},
  computed: {},
  mounted() {},
}
</script>
<style scoped>
.item {
  padding: 16px 16px;
  border-radius: 3px;
  background: #f5f5f5;
}
.dotted-left-border {
  border-left-style: dotted;
}

.red-border {
  border-color: #eb0e0e;
}
</style>
