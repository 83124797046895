<template>
  <v-card flat dark>
    <zoom-center-transition :duration="500">
      <v-card @click="onClick" flat class="no-background-hover">
        <v-card-text
          class="d-inline-block text-truncate pb-1"
          style="max-width: 65px"
          >{{ item.clearName }}</v-card-text
        >
        <v-card-text class="pt-1">
          <v-img
            :lazy-src="item.iconUrl"
            :src="item.iconUrl"
            height="50"
            width="50"
            max-height="50"
            max-width="50"
          ></v-img>
        </v-card-text>
        <v-row class="mt-1">
          <v-col cols="12" class="pt-0">
            <v-card flat>
              <v-card-text class="pt-0">
                <v-icon v-if="!subscribed"> mdi-plus </v-icon>
                <v-icon v-else> mdi-check </v-icon>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </zoom-center-transition>
  </v-card>
</template>

<script>

export default {
  props: {item: Object},
  data: () => ({}),
  methods: {
    async onClick() {
      if (this.subscribed) {
        await this.$store.dispatch('serviceList/deleteFromServiceList', this.item)
      } else {
        await this.$store.dispatch('serviceList/addToServiceList', { serviceId: this.item.jwProviderId, serviceName: this.item.slug })
      }
      this.subscribed = this.$store.getters['serviceList/serviceListItemExists'](
        this.item.jwProviderId
      )
    },
  },
  computed: {
    subscribed: {
      get() {
        const service = this.$store.getters['serviceList/getServiceListItem'](
          this.item.jwProviderId
        )
        return service
      },
      set() {},
    },
  },
  mounted() {},
}
</script>

<style scoped>
.no-background-hover::before {
  background-color: transparent !important;
}
</style>
