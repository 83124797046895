<template>
  <v-app>
    <v-main class="pt-2 pt-sm-2 pt-xs-2 pt-md-0 pt-lg-0 pt-xl-0">
      <!-- <transition name="slide-fade"> -->
      <router-view :key="$route.fullPath" />
      <!-- </transition> -->
      <message-snackbar></message-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import MessageSnackbar from './components/MessageSnackbar'

export default {
  name: 'App',
  components: {MessageSnackbar},
  data: () => ({}),
  methods: {},
  computed: {},
  async created() {
    this.$vuetify.theme.dark = true
  },
  async mounted() {
    await this.$store.dispatch('auth/setAuth')
    // await this.$store.dispatch('auth/setUser')

    await this.$store.dispatch('serviceList/fetchServiceList')
    await this.$store.dispatch('toWatchList/fetchToWatchList')
    await this.$store.dispatch('watchedList/fetchWatchedList')
    await this.$store.dispatch('likedList/fetchLikedList')
    await this.$store.dispatch('start/fetchKeywords')
    await this.$store.dispatch('providers/fetchProviders')
    await this.$store.dispatch('titleSearch/fetchTitles')
    if (this.$store.getters['auth/isSignedIn']()) {
      this.$store.dispatch('setMessageSnackbar', true)
    } else {
      this.$store.dispatch('setMessageSnackbar', false)
    }
  },
}
</script>

<style>
body {
  background-color: #272727;
  overflow-x: hidden;
  /* overflow-y: hidden; */
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

/* .vhl-item {
  padding-top: 29px !important;
} */

/* vue-horizontal-list scroller items */
/* .nc-item {
  padding-top: 16px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 47px !important;
} */

/* .vhl-navigation {
  margin-top: 0px !important;
}

*/

.vue-horizontal-list {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.vhl-list {
  padding-bottom: 18px !important;
  margin-bottom: 6px !important;
}

/* mobile portrait */
@media (max-width: 428px) {
  .vhl-btn-right {
    display: none !important;
  }
  .vhl-btn-left {
    display: none !important;
  }
}

/* mobile landscape */
@media (max-height: 428px) {
  .vhl-btn-right {
    display: none !important;
  }
  .vhl-btn-left {
    display: none !important;
  }
}

.vhl-btn-right {
  opacity: 0.5 !important;
  margin-right: -6px !important;
}

.vhl-btn-left {
  opacity: 0.5 !important;
  margin-left: -14px !important;
}

.vhl-item {
  padding-top: 4px !important;
  padding-bottom: 0px !important;
}
/*
.vhl-list {
  padding-bottom: 20px !important;
}
 */
</style>
