<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon :color="getIcon.color" small @click.native.prevent.stop.capture="onClicked" class="pr-4" v-bind="attrs" v-on="on">
        {{ getIcon.icon }}
      </v-icon>
    </template>
    <span>I liked this</span>
  </v-tooltip>
</template>

<script>

export default {
  props: {item: Object},
  data: () => ({}),
  methods: {
    async onClicked() {
      if (this.liked) {
        await this.$store.dispatch('likedList/deleteFromLikedList', this.item)
      } else {
        await this.$store.dispatch('likedList/addToLikedList', { ncTitleId: this.item.ncTitleId, imdbId: this.item.imdbId })
        await this.$store.dispatch('watchedList/addToWatchedList', { ncTitleId: this.item.ncTitleId, imdbId: this.item.imdbId })
      }
      this.liked = this.$store.getters['likedList/likedListItemExists'](
        this.item.ncTitleId
      )
    },
  },
  computed: {
    getIcon() {
      if (!this.liked) {
        return {
          icon: `mdi-heart-plus`,
          color: `white`
        }
      } else {
        return {
          icon: `mdi-heart-remove`,
          color: `orange`
        }
      }
    },
    liked: {
      get() {
        return this.$store.getters['likedList/likedListItemExists'](
          this.item.ncTitleId
        )
      },
      set() {}
    }
  },
  mounted() {},
}
</script>

<style scoped></style>
