<template>
  <v-container fluid>
    <v-card flat width="100%">
      <vue-horizontal-list v-if="hasItems" :items="items" :options="options">
        <template v-slot:default="{ item }">
          <nc-keyword-card :item="item"></nc-keyword-card>
        </template>
      </vue-horizontal-list>
    </v-card>
  </v-container>
</template>

<script>
import VueHorizontalList from 'vue-horizontal-list'
import NcKeywordCard from './NcKeywordCard.vue'

export default {
  components: { VueHorizontalList, NcKeywordCard },
  name: 'NcKeywordHorizontalList',
  props: ['items'],
  data: () => ({
    options: {
      // responsive: [
      //   { end: 480, size: 2 },
      //   { start: 480, end: 820, size: 3 },
      //   { start: 820, end: 960, size: 4 },
      //   { start: 960, end: 1100, size: 5 },
      //   { start: 1100, end: 1240, size: 6 },
      //   { start: 1240, end: 1380, size: 7 },
      //   { size: 8 },
      // ],
      responsive: [
        { end: 480, size: 2 },
        { start: 481, end: 768, size: 3 },
        { start: 769, end: 1024, size: 4 },
        { start: 1025, end: 1400, size: 6 },
        { size: 8 },
      ],
      navigation: {
        // when to show navigation
        start: 300,
      },
      position: {
        // Start from '1' on mounted.
        // start: 1,
      },
      list: {
        // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
        // windowed: 1200,
        // Because: #app {padding: 80px 24px;}
        // padding: 24,
      },
    },
  }),
  methods: {},
  computed: {
    hasItems() {
      return !!this.items.length
    },
  },
  mounted() {},
}
</script>
<style scoped>
.item {
  padding: 16px 16px;
  border-radius: 3px;
  background: #f5f5f5;
}
body {
  margin: 0;
  padding: 0;
}
</style>
