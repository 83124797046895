import axios from 'axios'
import comms from '../../shared/comms'

const client = axios.create({
  baseURL: comms.getBaseUrl(),
  json: true,
})

import { getAuth } from 'firebase/auth'

export default {
  namespaced: true,
  state: {
    toWatchList: [],
  },
  getters: {
    getToWatchList: ( state ) => () => {
      return state.toWatchList
    },
    getToWatchListItem: (state) => (ncTitleId) => {
      const item = state.toWatchList.find(item => item.ncTitleId === ncTitleId)
      return item
    },
    toWatchListItemExists: (state) => (ncTitleId) => {
      const item = state.toWatchList.find(item => item.ncTitleId === ncTitleId)
      return !!item
    },
  },
  mutations: {
    SET_TO_WATCH_LIST_ITEM(state, data) {
      state.toWatchList = [ ...data ]
      localStorage.setItem('toWatchList', JSON.stringify(state.toWatchList))
    },
    ADD_TO_TO_WATCH_LIST(state, data) {
      const list = [ ...state.toWatchList ]
      list.push(data);
      state.toWatchList = [ ...list ]
      localStorage.setItem('toWatchList', JSON.stringify(state.toWatchList))
    },
    DELETE_TO_WATCH_LIST_ITEM(state, ncTitleId) {
      const list = [ ...state.toWatchList ]
      state.toWatchList = list.filter(item => item.ncTitleId !== ncTitleId)
      localStorage.setItem('toWatchList', JSON.stringify(state.toWatchList))
    },
    CLEAR_TITLES(state) {
      state.toWatchList = []
      localStorage.clear()
    }
  },
  actions: {
    fetchToWatchList({ commit }) {
      const auth = getAuth()
      if (auth.currentUser) {
        auth.currentUser.getIdToken(true).then((idToken) => {
          const userEmail = auth.currentUser.email
          client({
            method: 'GET',
            url: `/api/to-watch/titles/${userEmail}`,
            headers: {
              AuthToken: idToken,
            },
          })
            .then((response) => {
              console.log(response)
              commit('SET_TO_WATCH_LIST_ITEM', response.data)
            })
            .catch((error) => {
              console.log(error)
            })
        })
      }
    },
    addToWatchList({ commit }, data) {
      commit('ADD_TO_TO_WATCH_LIST', data)
      const auth = getAuth()
      if (auth.currentUser) {
        auth.currentUser.getIdToken(true).then((idToken) => {
          const payload = {
            userEmail: auth.currentUser.email,
            uid: auth.currentUser.uid,
            ncTitleId: data.ncTitleId,
            imdbId: data.imdbId,
          }
  
          client({
            method: 'POST',
            url: '/api/to-watch/title',
            headers: {
              AuthToken: idToken,
            },
            data: payload,
          })
            .then((response) => {
              console.log(response)
            })
            .catch((error) => {
              console.log(error)
            })
        })
      } 
    },
    deleteFromToWatchList({ commit }, data) {
      commit('DELETE_TO_WATCH_LIST_ITEM', data.ncTitleId);
      const auth = getAuth()
      if (auth.currentUser) {
        auth.currentUser.getIdToken(true).then((idToken) => {
          const payload = {
            userEmail: auth.currentUser.email,
            uid: auth.currentUser.uid,
            ncTitleId: data.ncTitleId,
            imdbId: data.imdbId,
          }
  
          client({
            method: 'DELETE',
            url: '/api/to-watch/title',
            headers: {
              AuthToken: idToken,
            },
            data: payload,
          })
            .then((response) => {
              console.log(response)
            })
            .catch((error) => {
              console.log(error)
            })
        })
      } 
    },
    clearTitles({ commit }) {
      commit('CLEAR_TITLES')
    },
  },
}
