<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon small :color="getIcon.color" @click.native.prevent.stop.capture="onClicked" class="pr-4" v-bind="attrs" v-on="on">
        {{ getIcon.icon }}
      </v-icon>
    </template>
    <span>I want to watch this</span>
  </v-tooltip>
</template>

<script>

export default {
  props: {item: Object},
  data: () => ({
  }),
  methods: {
    async onClicked() {
      if (this.toWatch) {
        await this.$store.dispatch('toWatchList/deleteFromToWatchList', this.item)
      } else {
        await this.$store.dispatch('toWatchList/addToWatchList', { ncTitleId: this.item.ncTitleId, imdbId: this.item.imdbId })
      }
      this.toWatch = this.$store.getters['toWatchList/toWatchListItemExists'](
        this.item.ncTitleId
      )
    },
  },
  computed: {
    toWatch: {
      get() {
        return this.$store.getters['toWatchList/toWatchListItemExists'](
          this.item.ncTitleId
        )
      },
      set() {}
    },
    getIcon() {
      if (!this.toWatch) {
        return {
          icon: `mdi-movie-plus`,
          color: `white`
        }
      } else {
        return {
          icon: `mdi-movie-open-remove`,
          color: `orange`
        }
      }
    },
  },
  mounted() {},
}
</script>

<style scoped></style>
