import { getAuth } from 'firebase/auth'
// import { getAuth, onIdTokenChanged  } from 'firebase/auth'
// import { apiClientAuth } from '../../services/dataApiAuth'

export default {
  namespaced: true,
  state: {
    auth: null,
    user: '',
    isSignedIn: false,
    accessToken: null,
    emailAddress: null,
    userMessage: '',
  },
  getters: {
    getAuth: (state) => () => {
      return state.auth
    },
    getUser: (state) => () => {
      return state.user
    },
    isSignedIn: (state) => () => {
      const token = localStorage.getItem('accessToken')
      state.isSignedIn = !!token
      return state.isSignedIn
    },
    getEmailAddress: (state) => () => {
      const auth = getAuth()
      if (auth && auth.currentUser) {
        state.emailAddress = auth.currentUser.email
      } else {
        state.emailAddress = null
      }
      return state.emailAddress
    },
    getUserMessage: (state) => () => {
      return state.userMessage 
    }
  },
  mutations: {    
    SET_AUTH(state, data) {
      state.auth = data
    },
    SET_USER(state, data) {
      state.user = data
    },
    SET_ACCESS_TOKEN(state, data) {
      state.accessToken = data
      data ? localStorage.setItem('accessToken', data) : localStorage.removeItem('accessToken')
    },
    SET_EMAIL_ADDRESS(state, data) {
      state.emailAddress = data
      data ? localStorage.setItem('emailAddress', data) : localStorage.removeItem('emailAddress')
    },
    TOGGLE_IS_SIGNED_IN(state) {
      state.isSignedIn = !state.isSignedIn
    },
    SET_USER_MESSAGE(state, data) {
      state.userMessage = data
    },
  },
  actions: {
    setAuth( { commit }) {
      const auth = getAuth()
      commit('SET_AUTH', auth)
    },
    // setUser({commit, getters}) {
    //   const auth = getters.getAuth();
    //   onIdTokenChanged(auth, async user => {
    //     console.log("onIdTokenChanged", user);
    //     if (user) {
    //       // debugger
    //       const { token } = await user.getIdTokenResult();
    //       console.log('CLIENT TOKEN', token)
    //       apiClientAuth.post('/api/auth/current-user', {},)
    //       .then(res => {
    //         // debugger
    //         console.log('RES ====> ', res)
    //         commit('SET_USER', res.data)
    //       })
    //     }
    //   })
    // },
    setAccessToken({ commit }, accessToken) {
      commit('SET_ACCESS_TOKEN', accessToken)
    },
    setEmailAddress({ commit }, emailAddress) {
      commit('SET_EMAIL_ADDRESS', emailAddress)
    },
    toggleIsSignedIn({ commit }) {
      commit('TOGGLE_IS_SIGNED_IN')
    },
    setUserMessage( { commit }, userMessage) {
      commit('SET_USER_MESSAGE', userMessage)
    }
  },
}
