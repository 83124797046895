<template>
  <v-card flat dark class="pl-3 ml-0 mr-0 d-flex justify-space-around">
    <zoom-center-transition :duration="500">
      <v-btn
        class="keyword-button pl-1 pr-1"
        outlined
        label
        ripple
        x-large
        color="white"
        @click="onClick(item)"
        v-show="show"
        width="154"
        height="35"
      >
        {{ item }}
      </v-btn>
    </zoom-center-transition>
  </v-card>
</template>

<script>
export default {
  props: ['item'],
  data: () => ({
    show: false,
    selected: '',
    tier: null,
  }),
  methods: {
    onClick(keyword) {
      this.$store.dispatch('tierone/setScrollPosition', 0)
      this.$store.dispatch('tiertwo/setScrollPosition', 0)
      this.$store.dispatch('tierthree/setScrollPosition', 0)
      setTimeout(() => {
        switch (this.tier) {
          case 'tierone': {
            this.$store.dispatch('tierone/setSelected', keyword)
            this.$router.push(`/tiertwo/${this.selected}|${keyword}`)
            break
          }
          case 'tiertwo': {
            this.$store.dispatch('tiertwo/setSelected', keyword)
            this.$router.push(`/tierthree/${this.selected}|${keyword}`)
            break
          }
        }
      }, 300)
    },
  },
  mounted() {
    this.selected = this.$route.params.tierKey

    const tierLevel = this.selected.split('|')
    if (tierLevel.length === 1) {
      this.tier = 'tierone'
    }

    if (tierLevel.length === 2) {
      this.tier = 'tiertwo'
    }

    const setShow = () => {
      this.show = true
    }

    setTimeout(setShow, 250)
  },
}
</script>

<style scoped>
.keyword-button {
  height: 44px;
  /* border-radius: 22px; */
  border-color: #ff8721 !important;
  border-width: medium;
}

button {
  text-transform: unset !important;
}
</style>
