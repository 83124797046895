<template>
  <v-container v-if="hasItems" class="pa-0">
    <v-card outlined>
      <v-toolbar>Subscription Services</v-toolbar>

      <vue-horizontal-list
        v-if="hasItems"
        :items="getSubscription"
        :options="options"
      >
        <template v-slot:default="{ item }">
          <service-card :item="item"></service-card>
        </template>
      </vue-horizontal-list>
    </v-card>
    <v-card outlined>
      <v-toolbar>Free Services</v-toolbar>
      <vue-horizontal-list v-if="hasItems" :items="getFree" :options="options">
        <template v-slot:default="{ item }">
          <service-card :item="item"></service-card>
        </template>
      </vue-horizontal-list>
    </v-card>
    <v-card outlined>
      <v-toolbar>Cable / Bundled Services</v-toolbar>
      <vue-horizontal-list
        v-if="hasItems"
        :items="getBundled"
        :options="options"
      >
        <template v-slot:default="{ item }">
          <service-card :item="item"></service-card>
        </template>
      </vue-horizontal-list>
    </v-card>
  </v-container>
</template>

<script>
import VueHorizontalList from 'vue-horizontal-list'
import ServiceCard from './ServiceCard.vue'

export default {
  components: { VueHorizontalList, ServiceCard },
  name: 'ServicesHorizontalList',
  props: ['items'],
  data: () => ({
    options: {
      item: {
        // css class to inject into each individual item
        class: 'nc-item',
        // padding between each item
        padding: 8,
      },
      responsive: [
        { end: 480, size: 4 },
        { start: 481, end: 768, size: 6 },
        { start: 769, end: 1024, size: 8 },
        { start: 1025, end: 1400, size: 12 },
        { size: 16 },
      ],
      navigation: {
        // when to show navigation
        start: 200,
      },
      position: {
        // Start from '1' on mounted.
        start: 0,
      },
      list: {},
    },
  }),
  methods: {},
  computed: {
    hasItems() {
      return !!this.items.length
    },
    getSubscription() {
      const myServices = this.$store.getters['serviceList/getServiceList']()

      const allServices = this.items.filter((item) => item.providerType === 1)

      const myServiceItems = allServices.filter(function (item) {
        return myServices.includes(item.jwProviderId)
      })

      const moreServices = allServices.filter(function (item) {
        return !myServices.includes(item.jwProviderId)
      })

      return [...myServiceItems, ...moreServices]
    },
    getFree() {
      const myServices = this.$store.getters['serviceList/getServiceList']()
      const allServices = this.items.filter((item) => item.providerType === 2)

      const myServiceItems = allServices.filter(function (item) {
        return myServices.includes(item.jwProviderId)
      })

      const moreServices = allServices.filter(function (item) {
        return !myServices.includes(item.jwProviderId)
      })

      return [...myServiceItems, ...moreServices]
    },
    getBundled() {
      const myServices = this.$store.getters['serviceList/getServiceList']()
      const allServices = this.items.filter((item) => item.providerType === 3)

      const myServiceItems = allServices.filter(function (item) {
        return myServices.includes(item.jwProviderId)
      })

      const moreServices = allServices.filter(function (item) {
        return !myServices.includes(item.jwProviderId)
      })

      return [...myServiceItems, ...moreServices]
    },
  },
  mounted() {},
}
</script>
<style scoped>
/* .vue-horizontal-list {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
} */

/* .item {
  border-radius: 3px;
  background: #f5f5f5;
} */

/* .nc-item {
  padding-bottom: 32px;
} */

body {
  margin: 0;
  padding: 0;
}
</style>
