<template>
  <div>
    <v-autocomplete
      dense
      filled
      solo
      solo-inverted
      v-model="selectedTitle"
      :items="allTitles"
      item-text="title"
      item-value="id"
      class="search-padding-30"
      @change="getSelection"
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  data: () => ({
    selectedTitle: '',
  }),
  computed: {
    allTitles() {
      const titles = this.$store.getters['titleSearch/getTitles']()

      const searchList = titles.map((item) => {
        return {
          id: item.id,
          title: item.title,
        }
      })

      return searchList
    },
  },
  methods: {
    getSelection(value) {
      // get the transposed value of the ncTitleId
      const title = this.$store.getters['titleSearch/getNcTitleId'](value)

      this.$router.push(`/title/${title.ncTitleId}`)
    },
  },
}
</script>
<style scoped>
.search-padding-30 {
  padding-top: 30px !important;
}
</style>
