<template>
  <v-lazy min-height="200" transition="fade-transition">
    <vue-horizontal-list v-if="hasItems" :items="getItems" :options="options">
      <template v-slot:default="{ item }">
        <nc-title-card :item="item"></nc-title-card>
      </template>
    </vue-horizontal-list>
  </v-lazy>
</template>

<script>
import VueHorizontalList from 'vue-horizontal-list'
// import VueHorizontalList from '../vue-horizontal-list/vue-horizontal-list/src/vue-horizontal-list.vue'
import NcTitleCard from './NcTitleCards/NcTitleCard.vue'

export default {
  components: { VueHorizontalList, NcTitleCard },
  name: 'HorizontalList',
  props: ['type', 'items'],
  data: () => ({
    options: {
      item: {
        // css class to inject into each individual item
        class: 'nc-item',
        // padding between each item
        padding: 8,
      },
      // responsive: [
      //   { end: 480, size: 2 },
      //   { start: 480, end: 820, size: 3 },
      //   { start: 820, end: 960, size: 4 },
      //   { start: 960, end: 1100, size: 5 },
      //   { start: 1100, end: 1240, size: 6 },
      //   { start: 1240, end: 1380, size: 7 },
      //   { size: 8 },
      // ],
      responsive: [
        { end: 480, size: 2 },
        { start: 481, end: 768, size: 3 },
        { start: 769, end: 1024, size: 4 },
        { start: 1025, end: 1400, size: 6 },
        { size: 8 },
      ],
      navigation: {
        // when to show navigation
        start: 200,
      },
      position: {
        // Start from '1' on mounted.
        // start: 1,
      },
      list: {
        // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
        // windowed: 1200,
        // Because: #app {padding: 80px 24px;}
        // padding: 24,
      },
    },
  }),
  methods: {},
  computed: {
    hasItems() {
      if (this.type === 'provider') {
        return !!this.items.titleCount
      } else {
        return !!this.items.length
      }
    },
    getItems() {
      if (this.type === 'provider') {
        // get the current title filter
        const newAtStreaming = this.$store.getters.getNewAtStreaming()
        switch (newAtStreaming) {
          case '7':
            return this.items.subscriptions.sevenDaysTitles
          case '30':
            return this.items.subscriptions.thirtyDaysTitles
          default:
            return this.items.subscriptions.allTitles
        }
      } else {
        return this.items
      }
    },
  },
  mounted() {
  },
}
</script>
<style scoped>
.item {
  /* padding: 16px 16px; */
  border-radius: 3px;
  background: #f5f5f5;
}

.nc-item {
  /* padding-top: 8px; */
  padding-bottom: 32px;
}

body {
  margin: 0;
  padding: 0;
}
</style>
