<template>
  <v-rating
    :value="getRating(item.crowdRating)"
    half-increments
    readonly
    background-color="orange lighten-3"
    color="orange"
    x-small
    class="pl-0 d-flex justify-center"
  ></v-rating>
</template>

<script>
export default {
  props: {item: Object},
  data: () => ({}),
  methods: {
    getRating(data) {
      const getStarValue = (data) => {
        const pieces = `${data}`.split('.')
        let result = []
        if (pieces.length > 1) {
          const afterDecimalStringValue = `0.${pieces[1]}`
          const afterDecimalFloatValue = parseFloat(afterDecimalStringValue)
          if (afterDecimalFloatValue <= 0.25) {
            result[0] = pieces[0]
            result[1] = '.'
            result[2] = '0'
          } else if (
            afterDecimalFloatValue > 0.25 &&
            afterDecimalFloatValue <= 0.75
          ) {
            result[0] = pieces[0]
            result[1] = '.'
            result[2] = '5'
          } else {
            result[0] = `${parseInt(pieces[0]) + 1}`
            result[1] = '.'
            result[2] = '0'
          }
          return parseFloat(result.join(''))
        } else {
          return parseFloat(pieces[0])
        }
      }
      return getStarValue(data)
    },
  },
  computed: {
  },
  mounted() {},
}
</script>

<style scoped></style>
