<template>
  <v-card height="30" class="d-flex flex-row" flat tile>
    <div
      class="pl-3 mr-3 service-container"
      @click.prevent.stop.capture="toggleMySwitch"
    >
      <v-switch
        class="mt-0 mr-3"
        color="white"
        v-model="isMyServices"
        :label="`${isMyServices ? 'my' : 'all'}`"
        flat
      ></v-switch>
    </div>
    <v-btn
      v-if="isMyServices"
      class="mr-2 pl-0 pr-0 add-service-button"
      small
      outlined
      text
      @click.prevent.stop.capture="onAddServices"
      width="28"
    >
      <v-icon small> mdi-plus </v-icon>
    </v-btn>
    <v-btn
      class="mr-2"
      small
      outlined
      text
      @click.prevent.stop.capture="setOfferType('1')"
    >
      subscription
    </v-btn>
    <v-btn
      class="mr-2"
      small
      outlined
      text
      @click.prevent.stop.capture="setOfferType('2')"
    >
      free
    </v-btn>
    <v-btn small outlined text @click.prevent.stop.capture="setOfferType('3')">
      cable
    </v-btn>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    offerType: 0,
    checkbox: false,
  }),
  methods: {
    toggleMySwitch() {
      this.checkbox = !this.checkbox
      // console.log('toggleMySwitch')
      this.$store.dispatch('toggleServices')
    },
    setOfferType(val) {
      const currentOfferType = this.$store.getters.getCurrentOfferType()
      if (currentOfferType !== val) {
        this.$store.dispatch('toggleOffAllOffers')
        if (val === '1') {
          setTimeout(() => {
            this.$store.dispatch('toggleShowStreamingOffers')
          }, 100)
        } else if (val === '2') {
          setTimeout(() => {
            this.$store.dispatch('toggleShowFreeWithAdsOffers')
          }, 100)
        } else if (val === '3') {
          setTimeout(() => {
            this.$store.dispatch('toggleShowCableBundledOffers')
          }, 100)
        }
      }
    },
    onAddServices() {
      this.$router.push(`/services`)
    },
  },
  computed: {
    isMyServices() {
      return this.$store.getters.isMyServices()
    },
  },
}
</script>

<style scoped>
.service-container {
  height: 64px !important;
  width: 80px !important;
}

.add-service-button {
  min-width: 28px !important;
}

button {
  text-transform: unset !important;
}
</style>