import axios from 'axios'
import comms from '../../shared/comms'

const client = axios.create({
  baseURL: comms.getBaseUrl(),
  json: true,
})

import { getAuth } from 'firebase/auth'

export default {
  namespaced: true,
  state: {
    serviceList: [],
  },
  getters: {
    getServiceList: ( state ) => () => {
      return state.serviceList
    },
    getServiceListItem: (state) => (serviceId) => {
      const item = state.serviceList.find(item => item === serviceId)
      return item
    },
    serviceListItemExists: (state) => (serviceId) => {
      const item = state.serviceList.find(item => item === serviceId)
      return !!item
    },
  },
  mutations: {
    SET_SERVICE_LIST(state, data) {
      state.serviceList = [ ...data.map(item => item.serviceId) ]
      localStorage.setItem('serviceList', JSON.stringify(state.serviceList))
    },
    ADD_TO_SERVICE_LIST(state, data) {
      const list = [ ...state.serviceList ]
      list.push(data.serviceId);
      state.serviceList = [ ...list ]
      localStorage.setItem('serviceList', JSON.stringify(state.serviceList))
    },
    DELETE_SERVICE_LIST_ITEM(state, serviceId) {
      const list = [ ...state.serviceList ]
      state.serviceList = list.filter(item => item !== serviceId)
      localStorage.setItem('serviceList', JSON.stringify(state.serviceList))
    },
    CLEAR_SERVICES(state) {
      state.serviceList = [];
      localStorage.clear()
    }
  },
  actions: {
    fetchServiceList({ commit }) {
      const auth = getAuth()
      if (auth.currentUser) {
        auth.currentUser.getIdToken(true).then((idToken) => {
          const userEmail = auth.currentUser.email
          client({
            method: 'GET',
            url: `/api/services/${userEmail}`,
            headers: {
              AuthToken: idToken,
            },
          })
            .then((response) => {
              console.log(response)
              commit('SET_SERVICE_LIST', response.data)
            })
            .catch((error) => {
              console.log(error)
            })
        })
      }
    },
    addToServiceList({ commit }, data) {
      commit('ADD_TO_SERVICE_LIST', data)
      const auth = getAuth()
      if (auth.currentUser) {
        auth.currentUser.getIdToken(true).then((idToken) => {
          const payload = {
            uid: auth.currentUser.uid,
            userEmail: auth.currentUser.email,
            serviceId: data.serviceId,
            serviceName: data.serviceName,
          }
  
          client({
            method: 'POST',
            url: '/api/services/service',
            headers: {
              AuthToken: idToken,
            },
            data: payload,
          })
            .then((response) => {
              console.log(response)
            })
            .catch((error) => {
              console.log(error)
            })
        })
      } 
    },
    deleteFromServiceList({ commit }, data) {
      commit('DELETE_SERVICE_LIST_ITEM', data.jwProviderId);
      const auth = getAuth()
      if (auth.currentUser) {
        auth.currentUser.getIdToken(true).then((idToken) => {
          const payload = {
            userEmail: auth.currentUser.email,
            uid: auth.currentUser.uid,
            serviceId: data.jwProviderId,
            serviceName: data.slug,
          }
  
          client({
            method: 'DELETE',
            url: '/api/services/service',
            headers: {
              AuthToken: idToken,
            },
            data: payload,
          })
            .then((response) => {
              console.log(response)
            })
            .catch((error) => {
              console.log(error)
            })
        })
      } 
    },
    clearServices({ commit }) {
      commit('CLEAR_SERVICES');
    }
  },
}
