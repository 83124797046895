<template>
  <v-card elevation="0" color="#272727">
    <v-speed-dial
      v-model="fab"
      :top="top"
      :right="right"
      :direction="direction"
    >
      <template v-slot:activator>
        <v-btn x-small v-model="fab" elevation="0" class="no-background-hover">
          <!-- <img v-if="fab" src="../assets/images/icons/close.png" />
          <img v-else src="../assets/images/icons/dots-vertical.png" /> -->

          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else> mdi-dots-vertical </v-icon>
        </v-btn>
      </template>
      <v-list two-line subheader>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title>
              <a :href="aboutLink" target="_blank" class="more-item caption">
                About
              </a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title>
              <a :href="faqLink" target="_blank" class="more-item caption">
                FAQ
              </a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title>
              <a :href="feedbackLink" target="_blank" class="more-item caption">
                Feedback
              </a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title>
              <a
                :href="whatsnextLink"
                target="_blank"
                class="more-item caption"
              >
                What's Next
              </a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-speed-dial>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    direction: 'bottom',
    fab: false,
    top: true,
    right: true,
    bottom: false,
    aboutLink: 'https://nanocrowd.com/app-about',
    faqLink: 'https://nanocrowd.com/app-faq',
    feedbackLink: 'https://nanocrowd.com/app-feedback',
    whatsnextLink: 'https://nanocrowd.com/whats-next',
  }),
  methods: {},
  computed: {},
}
</script>

<style scoped>
.more-item {
  text-decoration: none;
  color: #fff;
}
.no-background-hover::before {
  background-color: transparent !important;
}
</style>
