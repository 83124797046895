import axios from 'axios'

// const baseURL = 'http://localhost:3100'
// const baseURL = 'https://bananastreampie.com'
const baseURL = 'https://streamtonio.com'
// const baseURL = 'http://137.184.37.198:3000'
// const baseURL = 'http://138.68.49.175:3000'
// const baseURL = 'http://137.184.117.207:3100'

const apiClient = axios.create({
  baseURL: `${baseURL}`,
  // timeout: 15000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export default {
  async getStartKeywords() {
    return await apiClient.get('/api/fundamentals')
  },
  async getProviders() {
    return await apiClient.get('/api/providers')
  },
  async getKeywordsAndTitles(key) {
    return await apiClient.get(`/api/tiers/${key}`)
  },
  async getTitleProviders(imdbId) {
    return await apiClient.get(`/api/streams/${imdbId}`)
  },
  async getTitleDetails(ncTitleId) {
    return await apiClient.get(`/api/titles/${ncTitleId}`)
  },
  async getTitles() {
    return await apiClient.get(`/api/titlesearch/`)
  },
  async playTitle(imdbId, url) {
    return await apiClient.get(`/api/streams/play/${imdbId}/${url}`)
  },
}
