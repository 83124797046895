import axios from 'axios'
import comms from '../../shared/comms'

const client = axios.create({
  baseURL: comms.getBaseUrl(),
  json: true,
})

import { getAuth } from 'firebase/auth'

export default {
  namespaced: true,
  state: {
    likedList: [],
  },
  getters: {
    getLikedList: ( state ) => () => {
      return state.likedList
    },
    getLikedListItem: (state) => (ncTitleId) => {
      const item = state.likedList.find(item => item.ncTitleId === ncTitleId)
      return item
    },
    likedListItemExists: (state) => (ncTitleId) => {
      const item = state.likedList.find(item => item.ncTitleId === ncTitleId)
      return !!item
    },
  },
  mutations: {
    SET_LIKED_LIST_ITEM(state, data) {
      state.likedList = [ ...data ]
      localStorage.setItem('likedList', JSON.stringify(state.likedList))
    },
    ADD_TO_LIKED_LIST(state, data) {
      const list = [ ...state.likedList ]
      list.push(data);
      state.likedList = [ ...list ]
      localStorage.setItem('likedList', JSON.stringify(state.likedList))
    },
    DELETE_LIKED_LIST_ITEM(state, ncTitleId) {
      const list = [ ...state.likedList ]
      state.likedList = list.filter(item => item.ncTitleId !== ncTitleId)
      localStorage.setItem('likedList', JSON.stringify(state.likedList))
    },
    CLEAR_TITLES(state) {
      state.likedList = []
      localStorage.clear()
    }
  },
  actions: {
    fetchLikedList({ commit }) {
      const auth = getAuth()
      if (auth.currentUser) {
        auth.currentUser.getIdToken(true).then((idToken) => {
          const userEmail = auth.currentUser.email
          client({
            method: 'GET',
            url: `/api/liked/titles/${userEmail}`,
            headers: {
              AuthToken: idToken,
            },
          })
            .then((response) => {
              console.log(response)
              commit('SET_LIKED_LIST_ITEM', response.data)
            })
            .catch((error) => {
              console.log(error)
            })
        })
      }
    },
    addToLikedList({ commit }, data) {
      commit('ADD_TO_LIKED_LIST', data)
      const auth = getAuth()
      if (auth.currentUser) {
        auth.currentUser.getIdToken(true).then((idToken) => {
          const payload = {
            userEmail: auth.currentUser.email,
            uid: auth.currentUser.uid,
            ncTitleId: data.ncTitleId,
            imdbId: data.imdbId,
          }
  
          client({
            method: 'POST',
            url: '/api/liked/title',
            headers: {
              AuthToken: idToken,
            },
            data: payload,
          })
            .then((response) => {
              console.log(response)
            })
            .catch((error) => {
              console.log(error)
            })
        })
      } 
    },
    deleteFromLikedList({ commit }, data) {
      commit('DELETE_LIKED_LIST_ITEM', data.ncTitleId);
      const auth = getAuth()
      if (auth.currentUser) {
        auth.currentUser.getIdToken(true).then((idToken) => {
          const payload = {
            userEmail: auth.currentUser.email,
            uid: auth.currentUser.uid,
            ncTitleId: data.ncTitleId,
            imdbId: data.imdbId,
          }
  
          client({
            method: 'DELETE',
            url: '/api/liked/title',
            headers: {
              AuthToken: idToken,
            },
            data: payload,
          })
            .then((response) => {
              console.log(response)
            })
            .catch((error) => {
              console.log(error)
            })
        })
      } 
    },
    clearTitles({ commit }) {
      commit('CLEAR_TITLES')
    },
  },
}
