<template>
  <v-card class="mb-6 pb-6" width="100%">
    <provider-offers
      v-if="isStreamingOffers"
      :tier="getStreamingTitleOffers"
    ></provider-offers>

    <provider-offers
      v-if="isFreeWithAdsOffers"
      :tier="getFreeTitleOffers"
    ></provider-offers>

    <provider-offers
      v-if="isCableBundledOffers"
      :tier="getBundledTitleOffers"
    ></provider-offers>
  </v-card>
</template>

<script>
import ProviderTypes from '../shared/ProviderTypes'
import ProviderOffers from '../components/ProviderOffers'
export default {
  components: {
    ProviderOffers,
  },
  props: ['tier'],
  data: () => ({
    show: false,
    selected: '',
    eager: true,
  }),
  methods: {},
  computed: {
    getStreamingTitleOffers() {
      const result = this.$store.getters[`${this.tier}/getProviderTitles`](
        ProviderTypes.STREAMING
      )
      return result
    },
    getFreeTitleOffers() {
      const result = this.$store.getters[`${this.tier}/getProviderTitles`](
        ProviderTypes.FREE
      )
      return result
    },
    getBundledTitleOffers() {
      const result = this.$store.getters[`${this.tier}/getProviderTitles`](
        ProviderTypes.BUNDLED
      )
      return result
    },
    // getMyOffers() {
    //   const result = this.$store.getters[`${this.tier}/getProviderTitles`](
    //     ProviderTypes.MY
    //   )
    //   return result
    // },
    getProviderImage() {
      return (providerId) => {
        const imageFile =
          this.$store.getters['providers/getProviderImage'](providerId)
        return imageFile
      }
    },
    getProviderSubUrl() {
      return (providerId) => {
        const url =
          this.$store.getters['providers/getProviderSubUrl'](providerId)
        return url || ''
      }
    },
    getProviderInfo() {
      return (providerId) => {
        const label =
          this.$store.getters['providers/getProviderName'](providerId)
        const url =
          this.$store.getters['providers/getProviderSubUrl'](providerId)
        const synopsis =
          this.$store.getters['providers/getProviderSynopsis'](providerId)
        return { label, url, synopsis }
      }
    },
    isStreamingOffers() {
      return this.$store.getters['isStreamingOffers']()
    },
    isFreeWithAdsOffers() {
      return this.$store.getters['isFreeWithAdsOffers']()
    },
    isCableBundledOffers() {
      return this.$store.getters['isCableBundledOffers']()
    },
    tabsModel: {
      set: function (val) {
        this.$store.dispatch('setCurrentTab', val)
      },
      get: function () {
        return this.$store.getters.getCurrentTab()
      },
    },
  },
  mounted() {
    const that = this
    this.selected = this.$route.params.tierKey.replaceAll('|', ' | ')
    setTimeout(function () {
      that.show = true
    }, 3000)
  },
}
</script>

<style scoped>
.streamer-logo {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.keyword {
  color: #ff8721 !important;
}

.hide {
  display: none !important;
}

.white-border {
  border-color: white;
}

.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-left: 15px;
  margin-right: 15px;
  /* margin-bottom: 20px; */
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

.scrolling-titles {
  height: 205px !important;
}

.scrolling-wrapper-flexbox .card {
  flex: 0 0 auto;
  margin-right: 3px;
}

.title-image:hover {
  cursor: pointer;
}

/* @media (min-width: 1025px) and (max-width: 1280px) { */
@media (min-width: 1025px) {
  /* CSS */

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* button */
  ::-webkit-scrollbar-button {
    background: #222;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #333;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #666;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000;
  }

  /* The track NOT covered by the handle. */
  ::-webkit-scrollbar-track-piece {
    background: #000;
  }

  /* Corner */
  ::-webkit-scrollbar-corner {
    background: #999;
  }

  /* Resizer */
  ::-webkit-resizer {
    background: #111;
  }
}
</style>
