<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon :color="getIcon.color" small @click.native.prevent.stop.capture="onClicked" class="pr-4" v-bind="attrs" v-on="on">
        {{ getIcon.icon }}
      </v-icon>
    </template>
    <span>I've watched this</span>
  </v-tooltip>
</template>

<script>

export default {
  props: {item: Object},
  data: () => ({}),
  methods: {
    async onClicked() {
      if (this.watched) {
        await this.$store.dispatch('watchedList/deleteFromWatchedList', this.item)
        await this.$store.dispatch('likedList/deleteFromLikedList', this.item)
      } else {
        await this.$store.dispatch('watchedList/addToWatchedList', { ncTitleId: this.item.ncTitleId, imdbId: this.item.imdbId })
      }
      this.watched = this.$store.getters['watchedList/watchedListItemExists'](
        this.item.ncTitleId
      )
      console.log(`this.watched: ${this.watched}`);
    },
  },
  computed: {
    getIcon() {
      if (!this.watched) {
        return {
          icon: `mdi-eye-plus`,
          color: `white`
        }
      } else {
        return {
          icon: `mdi-eye-remove`,
          color: `orange`
        }
      }
    },
    watched: {
      get() {
        return this.$store.getters['watchedList/watchedListItemExists'](
          this.item.ncTitleId
        )
      },
      set() {}
    }
  },
  mounted() {},
}
</script>

<style scoped></style>
