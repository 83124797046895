<template>
  <div>
    <transition name="slide">
      <div class="slidein" v-if="isOpen">
        <v-card class="mx-auto pa-4" v-on-clickaway="away">
          <v-card v-if="isSignedIn" flat outlined>
            <v-container class="pa-1" fluid>
              <!-- <p class="mb-0 subtitle-2 text--primary">Account</p> -->
              <v-btn block @click="onAccount()">
                <v-icon>mdi-account</v-icon>
                <v-spacer></v-spacer>
                <v-card-text>Account</v-card-text>
              </v-btn>
            </v-container>
          </v-card>
          <v-card v-if="!isSignedIn" flat outlined>
            <v-container class="pa-1" fluid>
              <v-btn block @click="onSignUp()">
                <v-icon>mdi-account-plus</v-icon>
                <v-spacer></v-spacer>
                <v-card-text>Sign Up (new accounts)</v-card-text>
              </v-btn>
            </v-container>
          </v-card>
          <v-card v-if="!isSignedIn" flat outlined>
            <v-container class="pa-1" fluid>
              <v-btn block @click="onSignIn()">
                <v-icon>mdi-login</v-icon>
                <v-spacer></v-spacer>
                <v-card-text>Sign In</v-card-text>
              </v-btn>
            </v-container>
          </v-card>
          <v-card v-if="isSignedIn" flat outlined>
            <v-container class="pa-1" fluid>
              <v-btn block @click="onSignOut()">
                <v-icon>mdi-logout</v-icon>
                <v-spacer></v-spacer>
                <v-card-text>Sign Out</v-card-text>
              </v-btn>
            </v-container>
          </v-card>
          <v-card flat outlined>
            <v-container class="pa-1" fluid>
              <v-btn block @click="onCancel()">
                <v-icon>mdi-cancel</v-icon>
                <v-spacer></v-spacer>
                <v-card-text>Cancel</v-card-text>
              </v-btn>
            </v-container>
          </v-card>
        </v-card>
      </div>
    </transition>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

import { getAuth, signOut } from 'firebase/auth'

export default {
  mixins: [clickaway],
  props: ['slideOpen'],
  components: {},
  data() {
    return {}
  },

  methods: {
    onAccount() {
      console.log('onAccount')
      this.$router.push('/account')
    },
    onSignUp() {
      console.log('onSignUp')
      this.$store.dispatch('toggleAccountSlideIn')
      this.$router.push('/register')
    },
    onSignIn() {
      console.log('onSignIn')
      this.$store.dispatch('toggleAccountSlideIn')
      this.$router.push('/login')
    },
    onSignOut() {
      console.log('onSignOut')
      this.$store.dispatch('toggleAccountSlideIn')
      const auth = getAuth()
      signOut(auth)
        .then(() => {
          console.log('signed out')
          this.$store.dispatch('auth/setAccessToken', null)
          this.$store.dispatch('auth/setEmailAddress')
          this.$store.dispatch('auth/toggleIsSignedIn')
          // this.$store.dispatch('myServices/clearServices')
          this.$store.dispatch('toWatchList/clearTitles')
          this.$store.dispatch('watchedList/clearTitles')
          this.$store.dispatch('likedList/clearTitles')
          this.$store.dispatch('serviceList/clearServices')
          this.$store.dispatch('setMessageSnackbar', false)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    onCancel() {
      console.log('onCancel')
      this.$store.dispatch('toggleAccountSlideIn')
    },
    away() {
      this.$store.dispatch('toggleAccountSlideIn')
    },
  },
  computed: {
    isOpen() {
      return this.$store.getters['showAccountSlideIn']()
    },
    isSignedIn() {
      return this.$store.getters['auth/isSignedIn']()
    },
  },
  mounted() {},
}
</script>

<style scope>
.slidein {
  opacity: 0.95;
  max-width: 300px;
  padding: 0em 0.5em 0em 0em;
  position: fixed;
  z-index: 100;
  top: 56px;
  right: 0;
  /* background: #121212; */
  /* height: 100%; */
  /* height: 50%; */
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  transition: all 0.25s ease-in-out;
}

/* before the element is shown, start off the screen to the right */
.slide-enter,
.slide-leave-active {
  right: -100%;
}
</style>
