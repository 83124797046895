<template>
  <div>
    <transition name="slide">
      <div class="slidein" v-if="open">
        <v-card class="mx-auto" v-on-clickaway="away">
          <v-card-title class="pt-0 pb-0">
            <v-container class="pt-0 pb-0 d-flex justify-center">
              <p class="mb-0 text-h5 text--primary">Settings</p>
            </v-container>
          </v-card-title>
          <v-card flat outlined>
            <v-container class="pa-1" fluid>
              <v-card-title class="pt-0 pb-0 pl-1 text">
                Offer Types
              </v-card-title>
              <v-radio-group class="mt-0" v-model="offerType" mandatory column>
                <v-radio color="white" label="Subscription" value="1"></v-radio>
                <v-radio
                  color="white"
                  label="Free with ads"
                  value="2"
                ></v-radio>
                <v-radio
                  color="white"
                  label="Cable / bundles"
                  value="3"
                ></v-radio>
                <v-radio color="white" label="My Services" value="4"></v-radio>
              </v-radio-group>
            </v-container>
          </v-card>
          <v-card flat outlined>
            <v-container class="pa-1" fluid>
              <v-card-title class="pt-0 pb-0 pl-1 text">
                Added to streaming
              </v-card-title>
              <v-radio-group
                class="mt-0"
                v-model="newAtStreaming"
                mandatory
                column
              >
                <v-radio
                  color="white"
                  label="Last 30 days"
                  value="30"
                ></v-radio>
                <v-radio color="white" label="Last 7 days" value="7"></v-radio>
                <v-radio color="white" label="All" value="all"></v-radio>
              </v-radio-group>
            </v-container>
          </v-card>
          <v-card v-if="false" flat outlined>
            <v-container class="pa-1" fluid>
              <v-card-title class="pl-1 text"> Show ratings </v-card-title>
              <v-radio-group
                class="mt-0"
                v-model="showRatings"
                mandatory
                column
              >
                <v-radio color="white" label="5 stars only" value="5"></v-radio>
                <v-radio
                  color="white"
                  label="4 stars and above"
                  value="4"
                ></v-radio>
                <v-radio
                  color="white"
                  label="3 stars and above"
                  value="3"
                ></v-radio>
                <v-radio
                  color="white"
                  label="2 stars and above"
                  value="2"
                ></v-radio>
                <v-radio
                  color="white"
                  label="1 stars and above"
                  value="1"
                ></v-radio>
              </v-radio-group>
            </v-container>
          </v-card>
        </v-card>
        <v-divider></v-divider>
        <v-card flat outlined @click="toggle()">
          <v-container class="d-flex justify-center">
            <v-btn text> done </v-btn>
          </v-container>
        </v-card>
      </div>
    </transition>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
export default {
  mixins: [clickaway],
  props: ['slideOpen'],
  data() {
    return {
      ratings: 0,
    }
  },
  methods: {
    toggle() {
      this.$store.dispatch('toggleFilterSlideIn')
    },
    away() {
      this.$store.dispatch('toggleFilterSlideIn')
    },
  },
  computed: {
    open() {
      return this.$store.getters['showFilterSlideIn']()
    },
    showRatings: {
      set: function (val) {
        this.ratings = val
      },
      get: function () {
        return this.ratings
      },
    },
    newAtStreaming: {
      set: function (val) {
        this.$store.state.newAtStreaming = val
      },
      get: function () {
        return this.$store.getters.getNewAtStreaming()
      },
    },
    offerType: {
      set: async function (val) {
        this.$store.dispatch('toggleOffAllOffers')
        if (val === '1') {
          setTimeout(() => {
            this.$store.dispatch('toggleShowStreamingOffers')
          }, 100)
        } else if (val === '2') {
          setTimeout(() => {
            this.$store.dispatch('toggleShowFreeWithAdsOffers')
          }, 100)
        } else if (val === '3') {
          setTimeout(() => {
            this.$store.dispatch('toggleShowCableBundledOffers')
          }, 100)
        }
      },
      get: function () {
        return this.$store.getters.getCurrentOfferType()
      },
    },
  },
  mounted() {},
}
</script>

<style scope>
.slidein {
  opacity: 0.95;
  max-width: 300px;
  padding: 0em 0.5em 0em 0em;
  position: fixed;
  z-index: 100;
  top: 56px;
  right: 0;
  /* background: #121212; */
  /* height: 100%; */
  /* height: 50%; */
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  transition: all 0.25s ease-in-out;
}

/* before the element is shown, start off the screen to the right */
.slide-enter,
.slide-leave-active {
  right: -100%;
}
</style>
