<template>
  <v-card
    v-if="hasData"
    class="d-flex justify-center no-background-hover"
    :class="getClass"
    max-width="200"
    dark
    flat
    @click="onTitleDetails(item.ncTitleId)"
  >
    <div class="item">
      <v-lazy min-height="185" transition="fade-transition">
        <v-img
          :src="`https://nccdn.sfo3.cdn.digitaloceanspaces.com/images/medium/${item.ncTitleId}.jpg`"
          min-height="185"
          min-width="130"
          max-height="185"
          max-width="130"
          class="rounded"
        ></v-img>
      </v-lazy>
      <v-row class="mt-1">
        <v-col cols="12" class="pa-0">
          <nc-title-rating :item="item"></nc-title-rating>
        </v-col>
      </v-row>
      <v-row class="mt-1">
        <v-col cols="12" class="pa-0">
          <v-card class="pl-0 no-background-hover" color="#444040" flat>
            <v-card color="#444040" flat>
              <v-card-text
                class="text-truncate pa-1 ml-2"
                style="max-width: 130px"
                >{{ item.title }} ({{ item.year }})</v-card-text
              >
            </v-card>
            <!-- This is the row of controls for saving user titles -->
            <v-card-text class="pl-3 pt-0 pb-0">
              <nc-title-to-watch-control class="pr-4" :item="item"></nc-title-to-watch-control>
              <nc-title-watched-control class="pr-4" :item="item"></nc-title-watched-control>
              <nc-title-liked-control :item="item"></nc-title-liked-control>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import NcTitleWatchedControl from './NcTitleWatchedControl'
import NcTitleToWatchControl from './NcTitleToWatchControl'
import NcTitleLikedControl from './NcTitleLikedControl'
import NcTitleRating from './NcTitleRating'

export default {
  // props: ['item'],
  props: {item: Object},
  components: { NcTitleRating, NcTitleWatchedControl, NcTitleToWatchControl, NcTitleLikedControl },
  data: () => ({}),
  methods: {
    onTitleDetails(ncTitleId) {
      this.$router.push(`/title/${ncTitleId}`)
    },
  },
  computed: {
    hasData() {
      return !!this.item.ncTitleId
    },
    getClass() {
      if (this.item.class) {
        return this.item.class
      } else {
        return 'fullOpacity'
      }
    },
  },
  mounted() {},
}
</script>

<style scoped>
.item {
  padding: 16px 16px;
  border-radius: 3px;
  background: #444040;
}
.fullOpacity {
  opacity: 1;
}

.reducedOpacity {
  opacity: 0.2;
}

.no-background-hover::before {
  background-color: transparent !important;
}
</style>
