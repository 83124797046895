<template>
  <div>
    <app-toolbar id="login" title="Nanocrowd"></app-toolbar>
    <v-container fluid fill-height class="background">
      <v-dialog v-model="showSignIn" max-width="600px" persistent>
        <v-card outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">Sign In</div>
              <v-list-item-title class="text-h5 mb-1">
                Sign In
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar tile size="80" color="grey">
              <v-icon large>mdi-account</v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <v-list-item v-if="showUserMessage">
            <v-card outlined>
              <v-card-text> {{ getUserMessage }} </v-card-text>
            </v-card>
          </v-list-item>
          <v-list-item>
            <v-text-field
              label="Email*"
              v-model="email"
              required
            ></v-text-field>
          </v-list-item>
          <v-list-item>
            <v-text-field
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              label="Password*"
              v-model="password"
              required
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </v-list-item>
          <v-list-item v-if="showError">
            <v-text-field
              outlined
              class="error-message-color"
              label="Error"
              v-model="error"
            ></v-text-field>
          </v-list-item>

          <v-card-actions>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-btn outlined block rounded text @click="onCancel">
                    cancel
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn outlined block rounded text @click="onSignIn">
                    continue
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-center">
                  <div class="text--primary">
                    Don’t Have an Account?
                    <v-btn
                      small
                      outlined
                      text
                      rounded
                      class="no-caps ml-4"
                      @click="onRegister"
                    >
                      Sign Up
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-center">
                  <v-btn
                    small
                    outlined
                    text
                    rounded
                    class="no-caps"
                    @click="onPasswordReset"
                  >
                    Forgot Password?
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppFooter from '../components/AppFooter'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

export default {
  components: { AppFooter },
  data: () => ({
    showPassword: false,
    email: '',
    password: '',
    error: null,
  }),
  methods: {
    onSignIn() {
      this.error = null
      const auth = getAuth()

      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((result) => {
          console.log(result)

          this.$store.dispatch('auth/setAccessToken', result.user.accessToken)
          this.$store.dispatch('auth/setEmailAddress', this.email)
          this.$store.dispatch('auth/setUserMessage', '')
          // this.$store.dispatch('myServices/fetchServices')
          this.$store.dispatch('setMessageSnackbar', true)
          this.$router.back()
        })
        .catch((error) => {
          if (
            error.code === 'auth/wrong-password' ||
            error.code === 'auth/invalid-email' ||
            error.code === 'auth/user-not-found'
          ) {
            this.error = 'Invalid sign in credentials.'
          } else {
            this.error = error.message
          }
        })
    },
    onRegister() {
      this.$router.replace('/register')
      this.$store.dispatch('auth/setUserMessage', '')
    },
    onPasswordReset() {
      this.$router.replace('/password-reset')
      this.$store.dispatch('auth/setUserMessage', '')
    },
    onCancel() {
      this.$router.back()
      this.$store.dispatch('auth/setUserMessage', '')
    },
  },
  computed: {
    showSignIn: {
      get() {
        return true
      },
      set() {},
    },
    showUserMessage() {
      const userMessage = this.$store.getters['auth/getUserMessage']()
      if (userMessage.length > 0) {
        return true
      } else {
        return false
      }
    },
    getUserMessage() {
      return this.$store.getters['auth/getUserMessage']()
    },
    showError() {
      return !!this.error
    },
  },
  mounted() {
    console.log('mounted: signIn')
  },
}
</script>

<style scoped>
.no-caps {
  text-transform: unset !important;
}

.error-message-color input {
  color: '#00CFFF' !important;
}

.background {
  background: linear-gradient(
      to bottom,
      rgba(37, 20, 20, 0.52),
      rgba(6, 4, 4, 0.73)
    ),
    url(../assets/images/landing1920-1080.jpg);
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

/* .transbox {
  opacity: 0.9;
}

.transbox h1,
h3,
p {
  font-weight: bold;
  color: white;
}

.stub {
  height: 160px;
} */
</style>