<template>
  <div>
    <app-toolbar id="account" title="Nanocrowd"></app-toolbar>
    <v-container fluid fill-height class="background">
      <v-card class="mx-auto my-12" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">Account</div>
            <v-list-item-title class="text-h5 mb-1">
              Settings
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-avatar tile size="80" color="grey">
            <v-icon large>mdi-account</v-icon>
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item>
          <v-text-field
            label="Email"
            v-model="getEmailAddress"
            disabled
          ></v-text-field>
        </v-list-item>
        <v-list-item>
          <v-btn outlined block rounded text @click="onResetPassword">
            Reset Password
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn outlined block rounded text @click="onDone"> Done </v-btn>
        </v-list-item>
      </v-card>
    </v-container>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppFooter from '../components/AppFooter'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
export default {
  components: { AppFooter },
  data: () => ({
    email: '',
    resetSent: false,
  }),
  methods: {
    async onResetPassword() {
      const auth = getAuth()

      const email = auth.currentUser.email

      sendPasswordResetEmail(auth, email).then(() => {
        console.log('password reset sent')
        this.resetSent = true
      })
    },
    onDone() {
      this.$router.back()
    },
  },
  computed: {
    getEmailAddress: {
      get() {
        return this.$store.getters['auth/getEmailAddress']()
      },
    },
  },
  mounted() {
    console.log('mounted: Account')
  },
}
</script>

<style scoped>
.background {
  background: linear-gradient(
      to bottom,
      rgba(37, 20, 20, 0.52),
      rgba(6, 4, 4, 0.73)
    ),
    url(../assets/images/landing1920-1080.jpg);
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
</style>
