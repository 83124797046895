<template>
  <div>
    <app-toolbar id="register" title="Nanocrowd"></app-toolbar>
    <v-container fluid fill-height class="background">
      <v-dialog v-model="showDialog" max-width="600px" persistent>
        <v-form ref="signUpForm" @submit.prevent="submit">
          <v-card outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">Account</div>
                <v-list-item-title class="text-h5 mb-1">
                  Change Email (Password required)
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-avatar tile size="80" color="grey">
                <v-icon large>mdi-account</v-icon>
              </v-list-item-avatar>
            </v-list-item>
            <v-list-item>
              <v-text-field
                ref="email"
                label="Email*"
                v-model="email"
                :rules="emailRules"
                required
              ></v-text-field>
            </v-list-item>
            <v-list-item>
              <v-text-field
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                label="Password*"
                v-model="password"
                required
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-list-item>
            <v-list-item>
              <v-card width="100%" class="mb-4" outlined>
                <v-card-actions>
                  <v-container>
                    <v-row>
                      <v-col cols="6">
                        <v-btn outlined block rounded text @click="onCancel">
                          cancel
                        </v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn
                          outlined
                          block
                          rounded
                          text
                          @click="onChangeEmail"
                        >
                          continue
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-actions>
              </v-card>
            </v-list-item>
          </v-card>
        </v-form>
      </v-dialog>
    </v-container>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppFooter from '../components/AppFooter'
import {
  getAuth,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  updateEmail,
} from 'firebase/auth'
export default {
  components: { AppFooter },
  data: () => ({
    showDialog: true,
    showPassword: false,
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || 'Invalid email address',
    ],
    error: null,
    password: null,
  }),
  methods: {
    async onChangeEmail() {
      this.$refs.signUpForm.validate()
      if (this.email.length > 0) {
        let userProvidedPassword = this.password
        const auth = getAuth()
        const credential = EmailAuthProvider.credential(
          auth.currentUser.email,
          userProvidedPassword
        )
        const result = await reauthenticateWithCredential(
          auth.currentUser,
          credential
        )
        if (result) {
          updateEmail(auth.currentUser, 'email address here')
            .then(() => {
              const protocol = window.location.protocol
              const server = window.location.hostname
              const port = window.location.port

              const actionCodeSettings = {
                url: `${protocol}//${server}:${port}/?email=${this.email}`,
              }
              // email updated
              sendEmailVerification(auth.currentUser, actionCodeSettings)
            })
            .catch((error) => {
              console.log(error)
            })
        }
      }
    },
    onCancel() {
      // this.$store.dispatch('toggleShowSignUp')
      this.$router.back()
    },
  },
  computed: {
    isValid() {
      let result = false
      const email = this.$refs.email
      if (email && email.valid) {
        result = true
      }
      return result
    },
  },
  mounted() {
    console.log('mounted: ChangeEmail')
    this.email = ''
  },
}
</script>

<style scoped>
.background {
  background: linear-gradient(
      to bottom,
      rgba(37, 20, 20, 0.52),
      rgba(6, 4, 4, 0.73)
    ),
    url(../assets/images/landing1920-1080.jpg);
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
</style>
