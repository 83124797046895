<template>
  <div>
    <app-toolbar id="streamers" title="Nanocrowd"></app-toolbar>
    <v-container fluid class="pt-16">
      <!-- <v-card> -->
      <services-collection :items="getProviders"> </services-collection>
      <!-- </v-card> -->
    </v-container>
  </div>
</template>

<script>
import ServicesCollection from '../components/Services/ServicesCollection'
export default {
  components: {
    ServicesCollection,
  },
  data: () => ({}),
  computed: {
    getProviders() {
      const providers = this.$store.getters['providers/getProviders']()
      return providers
    },
  },
}
</script>

<style scoped></style>