<template>
  <div class="text-center">
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      shaped
      color="primary"
    >
      {{getText()}}

    </v-snackbar>
  </div>
</template>

<script>
export default {
  // props: ['message'],
  data: () => ({
      // snackbar: true,
      timeout: 3000,
      message: 'Welcome. You are signed in as'
    }),
  methods: {
    getText() {
      return `${this.message}: ${this.$store.getters['auth/getEmailAddress']()}`
    }
  },
  computed: {
    snackbar: {
      get: function(){
        return this.$store.getters['showMessageSnackbar']()
      },
      set: function(val) {
        this.$store.dispatch('setMessageSnackbar', val)
      }
    }
  }
}
</script>
<style scoped>

</style>