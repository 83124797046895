<template>
  <span class="text-center d-flex align-center justify-space-around">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <!-- <v-btn
          class="position_top_left icon-size button-background"
          fab
          small
          depressed
          v-bind="attrs"
          v-on="on"
        >
          <img src="../assets/images/icons/information-outline.png" />
        </v-btn> -->

        <v-icon
          x-small
          class="position_top_left icon-size"
          v-bind="attrs"
          v-on="on"
          >mdi-information-outline</v-icon
        >
      </template>
      <span>{{ text }}</span>
    </v-tooltip>
  </span>
</template>
<script>
export default {
  props: ['text', 'xOffset', 'yOffset'],
  data: () => ({}),
  computed: {},
  methods: {},
  mounted() {
    const element = document.querySelector('.position_top_left')
    if (element) {
      element.setAttribute(
        'style',
        `top: ${this.xOffset}; left: ${this.yOffset};`
      )
    }
  },
}
</script>
<style scoped>
.position_top_left {
  top: 0px;
  left: 0px;
}

.button-background {
  background-color: #1e1e1e !important;
}
.icon-size {
  font-size: 18px;
}
</style>
