import Vue from 'vue'
import VueSocialSharing from 'vue-social-sharing'
import Transitions from 'vue2-transitions'
import VSwitch from 'v-switch-case'

import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

import firebaseConfig from './firebaseConfig'

import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'

import AppToolbar from './components/AppToolbar'

import ManageProfile from './components/ManageProfile'

const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth(firebaseApp)

Vue.config.productionTip = false
Vue.use(VueSocialSharing)
Vue.use(Transitions)
Vue.use(VSwitch)

Vue.component('app-toolbar', AppToolbar)
Vue.component('manage-profile', ManageProfile)

onAuthStateChanged(auth, user => {
  console.log('firebase user:', user)
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app')  
})

