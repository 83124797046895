const firebaseConfig = {
  apiKey: "AIzaSyD144mPkJqKx9XcTvjvIlJNzu5RrdZM5Ss",
  authDomain: "nanocrowd-9cfb0.firebaseapp.com",
  projectId: "nanocrowd-9cfb0",
  storageBucket: "nanocrowd-9cfb0.appspot.com",
  messagingSenderId: "58000701508",
  appId: "1:58000701508:web:7a29fe1913a32ce0886411",
  measurementId: "G-32KC1XW91Z"
};

export default firebaseConfig