<template>
  <div>
    <app-toolbar id="register" title="Nanocrowd"></app-toolbar>
    <v-container fluid fill-height class="background">
      <v-dialog v-model="showSignIn" max-width="600px" persistent>
        <v-form ref="signUpForm" @submit.prevent="submit">
          <v-card outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">Sign Up</div>
                <v-list-item-title class="text-h5 mb-1">
                  Create an Account
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-avatar tile size="80" color="grey">
                <v-icon large>mdi-account-plus</v-icon>
              </v-list-item-avatar>
            </v-list-item>
            <v-list-item>
              <v-text-field
                ref="email"
                label="Email*"
                v-model="email"
                :rules="emailRules"
                required
              ></v-text-field>
            </v-list-item>
            <v-list-item>
              <v-text-field
                ref="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                label="Password*"
                v-model="password"
                :rules="passwordRules"
                required
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-card width="100%" class="mb-4" outlined>
                <v-card-actions>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-list subheader>
                          <v-list-item>
                            <v-list-item-action>
                              <v-checkbox v-model="tosAgreement"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                                >Terms of Service</v-list-item-title
                              >
                              <v-list-item-subtitle
                                >By checking this box you agree to the 
                                terms of service</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-card-text>By clicking "CONTINUE" you agree to the 
                          <a href="https://nanocrowd.com/privacy-policy/" target="_blank">privacy policy</a> and
                          <a href="https://nanocrowd.com/terms" target="_blank">terms of use</a>
                        </v-card-text>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-btn outlined block rounded text @click="onCancel">
                          cancel
                        </v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn
                          :disabled="!isValid"
                          outlined
                          block
                          rounded
                          text
                          @click="onSignUp"
                        >
                          continue
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <div class="text--primary">
                          Already have an account?
                          <v-btn
                            small
                            outlined
                            text
                            rounded
                            class="no-caps ml-4"
                            @click="onSignIn"
                          >
                            Sign In
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-actions>
              </v-card>
            </v-list-item>
          </v-card>
        </v-form>
      </v-dialog>
    </v-container>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppFooter from '../components/AppFooter'
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from 'firebase/auth'

import axios from 'axios'
import comms from '../shared/comms'

const client = axios.create({
  baseURL: comms.getBaseUrl(),
  json: true,
})

export default {
  components: { AppFooter },
  data: () => ({
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || 'Invalid email address',
    ],
    showPassword: false,
    password: '',
    passwordRules: [
      (v) => !!v || 'Password is required',
      (v) =>
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) ||
        'Password must be at least six characters and contain at one least lowercase and uppercase letter, and one number.',
    ],
    error: null,
    tosAgree: false,
  }),
  methods: {
    onSignUp() {
      this.$refs.signUpForm.validate()
      if (this.email.length > 0 && this.password.length > 0) {
        let auth = getAuth()
        createUserWithEmailAndPassword(auth, this.email, this.password)
          .then((result) => {
            updateProfile(auth.currentUser, {
              displayName: this.email,
            })
              .then(() => {
                const protocol = window.location.protocol
                const server = window.location.hostname
                const port = window.location.port
                const actionCodeSettings = {
                  // url: `http://localhost:8080/?email=${this.email}`,
                  // url: `https://beta2.nanocrowd.com/?email=${this.email}`,
                  url: `${protocol}//${server}:${port}/?email=${this.email}`,
                }
                sendEmailVerification(auth.currentUser, actionCodeSettings)
                  .then(() => {
                    console.log('email sent')
                    auth = getAuth()
                    if (auth.currentUser) {
                      const payload = {
                        userEmail: auth.currentUser.email,
                        uid: auth.currentUser.uid,
                      }
                      auth.currentUser.getIdToken(true).then((idToken) => {
                        client({
                          method: 'POST',
                          url: '/api/members/add',
                          headers: {
                            AuthToken: idToken,
                          },
                          data: payload,
                        })
                          .then((response) => {
                            console.log(response)
                          })
                          .catch((error) => {
                            console.log(error)
                          })
                      })
                    } else {
                      console.log('no currentUser')
                    }
                  })
                  .catch((error) => {
                    console.log('unable to send email verification', error)
                  })
                this.$store.dispatch(
                  'auth/setAccessToken',
                  result.user.accessToken
                )
                this.$router.back()
              })
              .catch((error) => {
                this.error = error.message
              })
            // this.$store.dispatch('toggleShowSignUp')
          })
          .catch((error) => {
            this.error = error.message
            // this.$store.dispatch('toggleShowSignUp')
          })
      }
    },
    onSignIn() {
      this.$router.replace('/login')
    },
    onCancel() {
      // this.$store.dispatch('toggleShowSignUp')
      this.$router.back()
    },
  },
  computed: {
    showSignIn: {
      get() {
        return true
      },
      set() {},
    },
    tosAgreement: {
      get() {
        return this.tosAgree
      },
      set() {
        this.tosAgree = !this.tosAgree
      },
    },
    isValid() {
      let result = false
      const email = this.$refs.email
      const password = this.$refs.password
      if (
        this.tosAgree === true &&
        email &&
        email.valid &&
        password &&
        password.valid
      ) {
        result = true
      }
      return result
    },
  },
  mounted() {
    console.log('mounted: Register')
    this.email = ''
    this.password = ''
  },
}
</script>

<style scoped>
.background {
  background: linear-gradient(
      to bottom,
      rgba(37, 20, 20, 0.52),
      rgba(6, 4, 4, 0.73)
    ),
    url(../assets/images/landing1920-1080.jpg);
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
</style>
