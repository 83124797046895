<template>
  <v-card class="pl-0 pr-0 pb-0">
    <v-toolbar dense elevation="0" class="ml-0 pl-0" height="75">
      <span
        v-if="type === 'provider'"
        class="d-flex align-content-start flex-wrap"
      >
        <v-card color="#272727" class="d-flex flex-row" flat>
          <v-card color="#272727" class="pl-0 pr-0" flat>
            <v-btn
              large
              height="70"
              color="warning"
              :href="getProviderSubUrl(items.providerId)"
              target="_blank"
              class="pl-1 pr-1"
            >
              <div class="pa-1 d-flex align-content-start flex-wrap">
                <span class="d-flex flex-row">
                  <v-img
                    :src="`${getProviderImage(items.providerId)}`"
                    alt="provider"
                    height="50"
                    width="50"
                    class="rounded ml-0"
                  ></v-img>
                </span>
                <span class="pl-2">
                  <small style="display: block"> subscribe </small>
                  <span
                    class="d-inline-block text-truncate"
                    style="max-width: 200px"
                  >
                    {{ getProviderInfo(items.providerId).label }}
                  </span>
                  <div class="text-sm">
                    {{ items.titleCount }} titles
                    <span>
                      <v-icon small color=""> mdi-arrow-down-right </v-icon>
                    </span>
                  </div>
                </span>
              </div>
            </v-btn>
          </v-card>
          <v-card
            color="#272727"
            class="center-vertical pl-2 pr-2 d-none d-sm-block"
            flat
          >
            <div class="keyword text-md-body-1">{{ selected }}</div>
          </v-card>
        </v-card>
      </span>
      <span v-else class="d-flex align-content-start flex-wrap">
        <v-card color="#272727" flat>
          <div class="dotted-left-border red-border">
            <div
              class="
                d-sm-none
                text-md-body-1
                d-inline-block
                text-truncate
                pt-2
                pl-4
                keyword
              "
              style="max-width: 270px"
            >
              {{ title }}
            </div>
            <!-- hide on screens smaller than sm -->
            <div
              class="
                d-none d-sm-block
                pl-4
                padding-top-bottom-10
                text-md-body-1
                keyword
              "
            >
              {{ title }}
            </div>
          </div>
        </v-card>
      </span>
      <v-spacer></v-spacer>
      <span>
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" depressed>
              <v-icon>mdi-arrow-expand-all</v-icon>
            </v-btn>
          </template>

          <v-card class="d-flex flex-wrap" flat>
            <v-app-bar
              app
              dark
              dense
              elevation="0"
              class="app-bar-pad-0"
              height="86"
            >
              <v-toolbar-title v-if="type === 'provider'">
                <span class="d-flex align-content-start flex-wrap">
                  <v-card color="#272727" class="d-flex flex-row" flat>
                    <v-card color="#272727" class="pl-0 pr-0" flat>
                      <v-btn
                        large
                        height="70"
                        color="warning"
                        :href="getProviderSubUrl(items.providerId)"
                        target="_blank"
                        class="pl-1 pr-1"
                      >
                        <div class="pa-1 d-flex align-content-start flex-wrap">
                          <span class="d-flex flex-row">
                            <v-img
                              :src="`${getProviderImage(items.providerId)}`"
                              alt="provider"
                              height="50"
                              width="50"
                              class="rounded ml-0"
                            ></v-img>
                          </span>
                          <span class="pl-2">
                            <small style="display: block"> subscribe </small>
                            <span
                              class="d-inline-block text-truncate"
                              style="max-width: 200px"
                            >
                              {{ getProviderInfo(items.providerId).label }}
                            </span>
                            <div class="text-sm">
                              {{ items.titleCount }} titles
                              <span>
                                <v-icon small color="">
                                  mdi-arrow-down-right
                                </v-icon>
                              </span>
                            </div>
                          </span>
                        </div>
                      </v-btn>
                    </v-card>
                  </v-card>

                  <v-card
                    color="#272727"
                    class="center-vertical pl-2 pr-2 d-none d-sm-block"
                    flat
                  >
                    <div class="keyword text-md-body-1">{{ selected }}</div>
                  </v-card>
                </span>
              </v-toolbar-title>
              <v-toolbar-title class="keyword" v-if="type === 'tier'">{{
                title
              }}</v-toolbar-title>
              <v-toolbar-title v-if="type === 'mostlike'">
                <span class="dotted-left-border red-border ml-4 mb-0">
                  <span class="text-body-2 ml-4 mb-0">
                    Titles Most Like {{ title }}
                    {{ items.titleCount }}
                  </span>
                </span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-app-bar>
            <v-lazy min-height="200" transition="fade-transition">
              <div>
                <v-card
                  class="d-flex justify-space-between flex-wrap pt-12 mt-8 pt-8"
                  v-if="type === 'provider'"
                >
                  <nc-title-card
                    v-for="item in getCurrentFilterSet"
                    :key="item.providerId"
                    :item="item"
                    class="ma-2"
                  ></nc-title-card>
                </v-card>
                <v-card
                  class="d-flex justify-space-between flex-wrap mt-12 mt-8 pt-8"
                  v-else
                >
                  <nc-title-card
                    v-for="item in items"
                    :key="item.providerId"
                    :item="item"
                    class="ma-2"
                  ></nc-title-card>
                </v-card>
              </div>
            </v-lazy>
          </v-card>
        </v-dialog>
      </span>
    </v-toolbar>
  </v-card>
</template>

<script>
import NcTitleCard from './NcTitleCards/NcTitleCard'
export default {
  components: { NcTitleCard },
  name: 'HorizontalList',
  props: ['type', 'title', 'items'],
  data: () => ({
    dialog: false,
    selected: '',
  }),
  methods: {},
  computed: {
    getCurrentFilterSet() {
      const newAt = this.$store.getters.getNewAtStreaming()

      if (newAt === '7') {
        return this.items.subscriptions.sevenDaysTitles
      } else if (newAt === '30') {
        return this.items.subscriptions.thirtyDaysTitles
      } else {
        return this.items.subscriptions.allTitles
      }
    },
    getProviderImage() {
      return (providerId) => {
        if (this.providerId == 8) {
          console.log(
            this.$store.getters['providers/getProviderImage'](providerId)
          )
        }
        const imageFile =
          this.$store.getters['providers/getProviderImage'](providerId)
        return imageFile
      }
    },
    getProviderSubUrl() {
      return (providerId) => {
        const url =
          this.$store.getters['providers/getProviderSubUrl'](providerId)
        return url || ''
      }
    },
    getProviderInfo() {
      return (providerId) => {
        const label =
          this.$store.getters['providers/getProviderName'](providerId)
        const url =
          this.$store.getters['providers/getProviderSubUrl'](providerId)
        const synopsis =
          this.$store.getters['providers/getProviderSynopsis'](providerId)
        return { label, url, synopsis }
      }
    },
  },
  mounted() {
    if (this.type === 'provider') {
      this.selected = this.$route.params.tierKey.replaceAll('|', ' | ')
    }
  },
}
</script>
<style scoped>
.item {
  padding: 16px 16px;
  border-radius: 3px;
  background: #f5f5f5;
}
.dotted-left-border {
  border-left-style: dotted;
}

.red-border {
  border-color: #eb0e0e;
}

.subscribe-button {
  border-color: #ff8721 !important;
  border-width: medium;
}

.keyword {
  color: #ff8721 !important;
}

.center-vertical {
  padding: 20px;
}

.padding-top-bottom-10 {
  padding: 10px 0;
}
</style>
