import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import shared from './modules/shared'
import titleDetails from './modules/titleDetails'
import start from './modules/start'
import providers from './modules/providers'
import tierone from './modules/tierone'
import tiertwo from './modules/tiertwo'
import tierthree from './modules/tierthree'
import titleSearch from './modules/titleSearch'
import keywordProviders from './modules/keywordProviders'
// import myServices from './modules/myServices'
import serviceList from './modules/serviceList'
import toWatchList from './modules/toWatchList'
import watchedList from './modules/watchedList'
import likedList from './modules/likedList'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    searchOption: 'movie',
    currentKeywords: '',
    newAtStreaming: 'all',
    offerType: '1',
    showStreamingOffers: true,
    showFreeWithAdsOffers: false,
    showCableBundledOffers: false,
    showMyServices: false,
    showFilterSlideIn: false,
    showAccountSlideIn: false,
    showMessageSnackbar: false,
  },
  getters: {
    getSearchOption: (state) => () => {
      return state.searchOption
    },
    getCurrentKeywords: (state) => () => {
      return state.currentKeywords
    },
    getCurrentOfferType: (state) => () => {
      return state.offerType
    },
    getNewAtStreaming: (state) => () => {
      return state.newAtStreaming
    },
    isStreamingOffers: (state) => () => {
      return state.showStreamingOffers
    },
    isFreeWithAdsOffers: (state) => () => {
      return state.showFreeWithAdsOffers
    },
    isCableBundledOffers: (state) => () => {
      return state.showCableBundledOffers
    },
    isMyServices: (state) => () => {
      return state.showMyServices
    },
    showFilterSlideIn: (state) => () => {
      return state.showFilterSlideIn
    },
    showAccountSlideIn: (state) => () => {
      return state.showAccountSlideIn
    },
    showMessageSnackbar: (state) => () => {
      return state.showMessageSnackbar
    }
  },
  mutations: {
    SET_SEARCH_OPTION(state, option) {
      state.searchOption = option
    },
    SET_CURRENT_KEYWORDS(state, keywords) {
      state.currentKeywords = keywords
    },
    SET_NEW_AT_STREAMING(state, data) {
      if (data !== state.newAtStreaming) state.newAtStreaming = data
    },
    TOGGLE_SHOW_STREAMING_OFFERS(state) {
      state.offerType = '1'
      state.showStreamingOffers = true
      state.showFreeWithAdsOffers = false
      state.showCableBundledOffers = false
    },
    TOGGLE_SHOW_FREE_WITH_ADS_OFFERS(state) {
      state.offerType = '2'
      state.showStreamingOffers = false
      state.showFreeWithAdsOffers = true
      state.showCableBundledOffers = false
    },
    TOGGLE_SHOW_CABLE_BUNDLED_OFFERS(state) {
      state.offerType = '3'
      state.showStreamingOffers = false
      state.showFreeWithAdsOffers = false
      state.showCableBundledOffers = true
    },
    TOGGLE_SERVICES(state) {
      state.showMyServices = !state.showMyServices
    },
    TOGGLE_OFF_ALL_OFFERS(state) {
      state.showStreamingOffers = false
      state.showFreeWithAdsOffers = false
      state.showCableBundledOffers = false
    },
    TOGGLE_FILTER_SLIDE_IN(state) {
      state.showFilterSlideIn = !state.showFilterSlideIn
    },
    TOGGLE_ACCOUNT_SLIDE_IN(state) {
      state.showAccountSlideIn = !state.showAccountSlideIn
    },
    SET_MESSAGE_SNACKBAR(state, data) {
      state.showMessageSnackbar = data
    },
  },
  actions: {
    toggleServices({ commit }) {
      commit('TOGGLE_SERVICES')
    },
    toggleShowStreamingOffers({ commit }) {
      commit('TOGGLE_SHOW_STREAMING_OFFERS')
    },
    toggleShowFreeWithAdsOffers({ commit }) {
      commit('TOGGLE_SHOW_FREE_WITH_ADS_OFFERS')
    },
    toggleShowCableBundledOffers({ commit }) {
      commit('TOGGLE_SHOW_CABLE_BUNDLED_OFFERS')
    },
    toggleOffAllOffers({ commit }) {
      commit('TOGGLE_OFF_ALL_OFFERS')
    },
    toggleFilterSlideIn({ commit }) {
      commit('TOGGLE_FILTER_SLIDE_IN')
    },
    toggleAccountSlideIn({ commit }) {
      commit('TOGGLE_ACCOUNT_SLIDE_IN')
    },
    setSearchOption({ commit }, option) {
      commit('SET_SEARCH_OPTION', option)
    },
    setCurrentKeywords({ commit }, keywords) {
      commit('SET_CURRENT_KEYWORDS', keywords)
    },
    setNewAtStreaming({ commit }, data) {
      commit('SET_NEW_AT_STREAMING', data)
    },
    setMessageSnackbar({commit}, data) {
      commit('SET_MESSAGE_SNACKBAR', data)
    },
  },
  modules: {
    auth,
    shared,
    providers,
    start,
    tierone,
    tiertwo,
    tierthree,
    titleDetails,
    keywordProviders,
    titleSearch,
    // myServices,
    toWatchList,
    watchedList,
    likedList,
    serviceList,
  },
})
