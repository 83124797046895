export default {
  namespaced: true,
  state: {
    viewKey: 1,
    currentKeywords: [],
    loading: true,
  },
  getters: {
    getCurrentKeywords: (state) => () => {
      return [...state.currentKeywords]
    },
    getLoading: (state) => () => {
      return state.loading
    },
  },
  mutations: {
    SET_CURRENT_KEYWORDS(state, data) {
      state.currentKeywords = data
    },
    CLEAR_CURRENT_KEYWORDS(state) {
      state.currentKeywords = []
    },
    SET_LOADING(state, data) {
      state.loading = data
    },
  },
  actions: {
    setCurrentKeywords({ commit }, data) {
      commit('SET_CURRENT_KEYWORDS', data)
    },
    clearCurrentKeywords({ commit }) {
      commit('CLEAR_CURRENT_KEYWORDS')
    },
    setLoading({ commit }, data) {
      commit('SET_LOADING', data)
    },
  },
}
