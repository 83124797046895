<template>
  <div>
    <v-card d-flex flex-row>
      <v-container class="background" fill-height>
        <v-row class="transbox flex-column fill-height">
          <v-col cols="auto" class="grow d-flex align-end mb-n6 justify-end">
            <h1>Nanocrowd</h1>
          </v-col>
          <v-col cols="auto" class="d-flex align-end justify-end mb-n6"
            ><p>
              Find movies and series you’ll love and find the best streaming
              services for you
            </p></v-col
          >
          <v-col cols="auto" class="d-flex align-end justify-end">
            <v-chip
              class="pa-4"
              color="red darken-4"
              label
              @click="getStarted('movie')"
              ><span style="color: white">Find a movie or series</span>
              <v-icon style="color: white">mdi-chevron-right</v-icon>
              <!-- <img src="../assets/images/icons/chevron-right.png" /> -->
            </v-chip>
          </v-col>
          <v-col cols="auto" class="d-flex align-end justify-end mb-n6">
            <v-chip
              class="pa-4"
              color="red darken-4"
              label
              @click="getStarted('provider')"
              ><span style="color: white">Find a new streaming service</span>
              <v-icon style="color: white">mdi-chevron-right</v-icon>
              <!-- <img src="../assets/images/icons/chevron-right.png" /> -->
            </v-chip>
          </v-col>
          <v-col
            cols="auto"
            class="d-flex align-end justify-end mb-n6 stub"
          ></v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Home',

  components: {},
  methods: {
    getStarted(option) {
      this.$store.dispatch('setSearchOption', option)
      this.$router.push(`/start`)
    },
  },
}
</script>

<style scoped>
.background {
  background: linear-gradient(
      to bottom,
      rgba(37, 20, 20, 0.52),
      rgba(6, 4, 4, 0.73)
    ),
    url(../assets/images/landing1920-1080.jpg);
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.transbox {
  opacity: 0.9;
}

.transbox h1,
h3,
p {
  font-weight: bold;
  color: white;
}

.stub {
  height: 160px;
}
</style>
