<template>
  <div v-if="getTitleDetails" class="ml-0 mr-0">
    <app-toolbar id="TitleDetails" :title="getTitle"></app-toolbar>
    <v-container class="mt-16 mb-6">
      <v-card class="details-background">
        <ul class="flex-container mb-2">
          <li class="flex-item title-image mb-6">
            <v-card outlined>
              <v-img
                max-width="560"
                max-height="800"
                :src="`https://nccdn.sfo3.cdn.digitaloceanspaces.com/images/large/${id}.jpg`"
              ></v-img>
              <v-card-text>
                <v-row align="center" class="mx-0">
                  <v-col align-self="center" cols="12" class="pb-0 pt-1">
                    <div class="grey--text">{{ getTitle }} - {{ getType }}</div>
                  </v-col>
                </v-row>
                <v-row align="center" class="mx-0">
                  <v-col cols="12" align-self="center" class="pa-0">
                    <v-rating
                      half-increments
                      v-model="getRating"
                      background-color="orange lighten-3"
                      color="orange"
                      readonly
                      small
                    ></v-rating>
                    <element-info-tip
                      text="Nanocrowd's Crowd Ratings are based on the words viewers use to describe the things they've watched."
                      xOffset="-35px"
                      yOffset="85px"
                    ></element-info-tip>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </li>
          <li class="flex-item">
            <v-card outlined>
              <v-toolbar dark>
                <trailer-dialog
                  :youTubeId="getYouTubeId"
                  class="mt-2"
                ></trailer-dialog>
                <v-spacer></v-spacer>
                <a v-if="!isSeries" :href="getLetterboxdLink" target="_blank"
                  ><v-img
                    max-width="75"
                    max-height="75"
                    src="../assets/images/letterboxd-logo-v-neg-rgb-1000px.png"
                  ></v-img
                ></a>
                <v-spacer></v-spacer>
                <a :href="getImdbLink" target="_blank" class="pt-1"
                  ><v-img
                    max-width="53"
                    max-height="53"
                    src="../assets/images/imdb_wide._V1_.png"
                  ></v-img
                ></a>
                <v-spacer></v-spacer>
                <span class="text-caption mr-2 mt-1">Buy</span>
                <a :href="getAmazonLink" target="_blank" class="play-icon pt-1"
                  ><v-img
                    max-width="24"
                    max-height="24"
                    src="../assets/images/amazon_logo.png"
                  ></v-img
                ></a>
              </v-toolbar>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="(item, index) in getProviderItems"
                      :key="index"
                      @click="navToProvider(item.url)"
                    >
                      <td class="overline pa-4">
                        <v-img width="60" :src="`${item.iconUrl}`"></v-img>
                      </td>
                      <td class="overline">{{ item.clearName }}</td>
                      <td class="overline">
                        <v-icon large>mdi-play-box</v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-card-text v-if="getProviders.length">
                <v-row align="center" class="mx-0">
                  <div>
                    <a
                      style="
                        font-size: 11px;
                        font-family: sans-serif;
                        color: white;
                        text-decoration: none;
                      "
                      target="_blank"
                      data-original="https://www.justwatch.com/us"
                      href="https://www.justwatch.com/us"
                    >
                      Powered by
                      <span
                        style="
                          display: -moz-inline-box;
                          display: inline-block;
                          width: 66px;
                          height: 10px;
                          margin-left: 3px;
                          background: url(https://widget.justwatch.com/assets/JW_logo_color_10px.svg);
                          overflow: hidden;
                          text-indent: -3000px;
                        "
                        >JustWatch</span
                      >
                    </a>
                  </div>
                </v-row>
              </v-card-text>
            </v-card>
          </li>
          <li class="flex-item">
            <v-card class="mb-5" outlined>
              <div class="text-body-2 ml-4 mt-6 mb-4">
                Description from viewers
              </div>
              <cloud
                :data="getKeywords"
                :fontSizeMapper="fontSizeMapper"
                height="400"
                width="327"
                :onWordClick="cloudWordClicked"
              />
            </v-card>
          </li>
        </ul>

        <v-card class="details-background">
          <v-container>
            <nc-title-collection
              :title="getTitle"
              :items="getMostLikeTitles"
              type="mostlike"
            ></nc-title-collection>
          </v-container>
        </v-card>
      </v-card>
    </v-container>
  </div>
</template>

<script>
// https://nccdn.sfo3.cdn.digitaloceanspaces.com/images/large/10000.jpg
import Cloud from 'vue-d3-cloud'
import TrailerDialog from '../components/TrailerDialog'
import ElementInfoTip from '../components/ElementInfoTip'

import NcTitleCollection from '../components/NcTitleLists/NcTitleCollection'

export default {
  props: ['id'],
  components: {
    Cloud,
    NcTitleCollection,
    TrailerDialog,
    ElementInfoTip,
  },
  data: () => ({
    titleData: null,
    titleId: null,
    drawer: false,
    group: null,
    fontSizeMapper: (word) => Math.log2(word.value) * 1.9,
    rotateMapper: () => ~~(Math.random() * 5) * 30 - 60,
    param: null,
    providers: [],
    providerDetails: [],
  }),
  methods: {
    async titleClicked(id) {
      this.titleId = id
      await this.$store.dispatch('fetchTitleProviderDetails', {
        id: this.titleId,
      })
      await this.$store.dispatch('fetchTitleListDetails', {
        id: this.titleId,
      })

      this.titleData = this.$store.getters.getTitleProviderDetailsData()
      setTimeout(() => {
        window.scrollTo({
          left: 0,
          top: 0,
          behavior: 'smooth',
        })
      }, 200)
    },
    async playTitleClicked(url) {
      this.$store.dispatch('titleDetails/playTitle', url)
    },
    async navToProvider(url) {
      this.$store.dispatch('titleDetails/playTitle', url)
      window.open(url, '_blank')
    },
    cloudWordClicked() {},
  },
  computed: {
    getTitle() {
      const title = this.$store.getters['titleDetails/getTitle']()
      const year = this.$store.getters['titleDetails/getYear']()
      return `${title} (${year})`
    },
    getType() {
      return this.$store.getters['titleDetails/getType']()
    },
    getAmazonLink() {
      const title = this.$store.getters['titleDetails/getTitle']()
      const url = `https://www.amazon.com/s?k=${title.replaceAll(
        ' ',
        '+'
      )}&i=movies-tv&camp=1789&creative=9325&linkCode=ur2&tag=nanocrowd00-20`

      return url
    },
    getImdbLink() {
      const imdbId = this.$store.getters['titleDetails/getImdbId']()
      const url = `https://imdb.com/title/${imdbId}`
      return url
    },
    isSeries() {
      return this.$store.getters['titleDetails/isSeries']()
    },
    getLetterboxdLink() {
      const imdbId = this.$store.getters['titleDetails/getImdbId']()
      const url = `https://letterboxd.com/imdb/${imdbId}`
      return url
    },
    getMenuItems() {
      const result = []
      const firstKeyword = this.$store.getters['start/getSelected']()
      const secondKeyword = this.$store.getters['tierone/getSelected']()
      const thirdKeyword = this.$store.getters['tiertwo/getSelected']()
      if (firstKeyword) {
        result.push(firstKeyword)
      }
      if (secondKeyword) {
        result.push(secondKeyword)
      }
      if (thirdKeyword) {
        result.push(thirdKeyword)
      }
      return result
    },
    getTitleDetails() {
      return this.$store.getters['titleDetails/getTitle']()
    },
    getProviders() {
      return this.$store.getters['titleDetails/getProviders']()
    },
    getProviderItems() {
      const providerData = this.$store.getters['titleDetails/getProviders']()
      const allProviders = providerData.map((item) => item.justwatchProviderId)
      const uniqueProviders = [...new Set(allProviders)]
      const result = []
      for (let x = 0; x < uniqueProviders.length; x++) {
        const provider = providerData.filter(
          (item) => item.justwatchProviderId === uniqueProviders[x]
        )
        let providerObj = {
          justwatchProviderId: '',
          clearName: '',
          iconUrl: '',
          url: '',
        }
        for (let xx = 0; xx < provider.length; xx++) {
          providerObj.justwatchProviderId = provider[xx].justwatchProviderId
          providerObj.url = provider[xx].url
          providerObj.iconUrl = this.$store.getters[
            'providers/getProviderImage'
          ](providerObj.justwatchProviderId)
          providerObj.clearName = this.$store.getters[
            'providers/getProviderName'
          ](providerObj.justwatchProviderId)
        }
        if (
          providerObj.iconUrl.length &&
          providerObj.clearName.length &&
          providerObj.url.length
        ) {
          result.push(providerObj)
        }
      }
      if (result.length > 0) {
        return result
      } else {
        return null
      }
    },
    getRating() {
      const getStarValue = (data) => {
        const pieces = `${data}`.split('.')
        let result = []
        if (pieces.length > 1) {
          const afterDecimalStringValue = `0.${pieces[1]}`
          const afterDecimalFloatValue = parseFloat(afterDecimalStringValue)
          if (afterDecimalFloatValue <= 0.25) {
            result[0] = pieces[0]
            result[1] = '.'
            result[2] = '0'
          } else if (
            afterDecimalFloatValue > 0.25 &&
            afterDecimalFloatValue <= 0.75
          ) {
            result[0] = pieces[0]
            result[1] = '.'
            result[2] = '5'
          } else {
            result[0] = `${parseInt(pieces[0]) + 1}`
            result[1] = '.'
            result[2] = '0'
          }
          return parseFloat(result.join(''))
        } else {
          return parseFloat(pieces[0])
        }
      }

      return getStarValue(this.$store.getters['titleDetails/getRating']())
    },
    getMostLikeTitles() {
      const data = this.$store.getters['titleDetails/getMostLikeTitles']()
      if (data) {
        return data.slice(0, 100)
      }

      return []
    },
    getKeywords() {
      // WordWeightNew = ((WordWeightInitial / WeightDivisor) ^ WeightExponent
      const weightDivisor = 29
      const weightExponent = 4.5

      const data = this.$store.getters['titleDetails/getKeywords']()
      const result = []

      for (let x = 0; x < data.length; x++) {
        let wordValue = Math.pow(
          parseFloat(data[x].fpm) / weightDivisor,
          weightExponent
        )
        result.push({ text: data[x].term, value: wordValue })
      }

      return result
    },
    getYouTubeId() {
      const data = this.$store.getters['titleDetails/getYouTubeId']()
      if (data.trim().length > 0) {
        return data
      } else {
        return false
      }
    },
    getScrollHeight() {
      return document.body.scrollHeight
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  async mounted() {
    // console.log("TitleDetails.vue: mounted");

    await this.$store.dispatch('titleDetails/fetchTitleDetails', this.id)
    await this.$store.dispatch('titleDetails/fetchTitleProviders')

    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth',
      })
    }, 200)
  },
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
}
</script>

<style scoped>
.dotted-left-border {
  border-left-style: dotted;
}

.details-background {
  background-color: #121212;
  /* background-color: gray; */
}
.red-border {
  border-color: #eb0e0e;
}

.flex-container {
  /* We first create a flex layout context */
  display: flex;

  /* Then we define the flow direction
     and if we allow the items to wrap
   * Remember this is the same as:
   * flex-direction: row;
   * flex-wrap: wrap;
   */
  flex-flow: row wrap;

  /* Then we define how is distributed the remaining space */
  justify-content: space-around;

  padding: 0;
  margin: 0;
  list-style: none;
}

.flex-item {
  /* background: tomato; */
  padding: 5px;
  width: 374px;
  margin-top: 10px;
  line-height: 50px;
  color: white;
  font-weight: bold;
  font-size: 3em;
  text-align: center;
}

.title-image {
  height: 600px;
}

.play-icon {
  text-decoration: none;
}
</style>
