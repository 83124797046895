import dataApi from '../../services/dataApi'

export default {
  namespaced: true,
  state: {
    keywords: [],
    selectedKeyword: null,
  },
  getters: {
    getKeywords: (state) => () => {
      return state.keywords
    },
    getSelected: (state) => () => {
      return state.selectedKeyword
    },
  },
  mutations: {
    SET_KEYWORDS(state, data) {
      state.keywords = data.map((item) => item.tierKey)
    },
    SET_SELECTED_KEYWORD(state, data) {
      state.selectedKeyword = data
    },
    CLEAR_CURRENT_KEYWORD(state) {
      state.selectedKeyword = null
    },
    SET_PROGRESS_INDICATOR_ON() {
      this.state.shared.loading = true
    },
    SET_PROGRESS_INDICATOR_OFF() {
      const that = this
      setTimeout(() => {
        that.state.shared.loading = false
      }, 1000)
    },
  },
  actions: {
    async fetchKeywords({ commit }) {
      commit('SET_PROGRESS_INDICATOR_ON')
      let response = await dataApi.getStartKeywords()
      commit('SET_PROGRESS_INDICATOR_OFF')
      if (response.data) {
        commit('SET_KEYWORDS', response.data)
      }
    },
    setSelected({ commit }, keyword) {
      commit('SET_SELECTED_KEYWORD', keyword)
    },
    clearCurrentKeyword({ commit }) {
      commit('CLEAR_CURRENT_KEYWORD')
    },
  },
}
